import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import InputField from "../common/form/InputField";
import NumberField from "../common/form/NumberField";
import AddEditFormCard from "../common/form/AddEditFormCard";

interface OrderTotalViewProps {
    title?: string,
    discount: boolean,
    shipping: boolean,
    disabled?: boolean
}
const OrderTotalView = (props: OrderTotalViewProps) => {
    const { title, discount, shipping, disabled } = props;
    return (
        <AddEditFormCard title={title ?? "Total"} withExpand>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell >HT</TableCell>
                        <TableCell >TVA</TableCell>
                        <TableCell >TTC</TableCell>
                        <TableCell >{(discount || shipping) && "%"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(discount || shipping) &&
                        <TableRow>
                            <TableCell>Produits</TableCell>
                            <TableCell>
                                <NumberField
                                    name="total_tax_excl"
                                    disabled={true}
                                    minDecimals={2}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberField
                                    name="total_tax"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberField
                                    name="total_tax_incl"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    }
                    {discount &&
                        <>
                            <TableRow>
                                <TableCell>Remise</TableCell>
                                <TableCell>
                                    <NumberField
                                        name="discount_tax_excl"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="discount_tax"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="discount_tax_incl"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="discount_percent"
                                        label="% Remise"
                                        disabled={disabled}
                                    />
                                </TableCell>
                            </TableRow>
                            {shipping &&
                                <TableRow>
                                    <TableCell>Total avec remise</TableCell>
                                    <TableCell>
                                        <NumberField
                                            name="total_minus_discount_tax_excl"
                                            minDecimals={2}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField
                                            name="total_minus_discount_tax"
                                            minDecimals={2}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField
                                            name="total_minus_discount_tax_incl"
                                            minDecimals={2}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            }
                        </>
                    }
                    {shipping &&
                        <TableRow>
                            <TableCell>Livraison</TableCell>

                            <TableCell>
                                <NumberField
                                    name="shipping_tax_excl"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberField
                                    name="shipping_tax"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberField
                                    name="shipping_tax_incl"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberField
                                    name="shipping_vat"
                                    label={"% TVA Livraison"}
                                    disabled={disabled}
                                />
                            </TableCell>
                        </TableRow>
                    }
                    <TableRow>
                        <TableCell>Total</TableCell>
                        <TableCell>
                            <NumberField
                                name="total_tax_excl_plus_shipping_tax_excl"
                                minDecimals={2}
                                disabled={true}
                            />
                        </TableCell>
                        <TableCell>
                            <NumberField
                                name="total_tax_plus_shipping_tax"
                                minDecimals={2}
                                disabled={true}
                            />
                        </TableCell>
                        <TableCell>
                            <NumberField
                                name="total_tax_incl_plus_shipping_tax_incl"
                                minDecimals={2}
                                disabled={true}
                            />
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </AddEditFormCard>
    );
}

export default OrderTotalView;