import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@mui/material';
function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const name_splitted = name.split(' ');
  const name_value = (name_splitted.length > 1 ? `${name_splitted[0][0]}` : name_splitted[0][0]);
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name_value,
  };
}

const AvatarIcon = (props: { value: string }) => {
  const { value } = props;

  const valueUppered = React.useMemo(() => {
    return (typeof value === "string") ? value.replace(/\s+/g, ' ').trim().toUpperCase() : "";
  }, [value])

  return <Tooltip title={value}>
    <Avatar
      style={{ width: 30, height: 30 }}
      {...stringAvatar(valueUppered)}
    />
  </Tooltip>
}

export default AvatarIcon;