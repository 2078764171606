import { Box } from "@mui/material";
import { useMemo } from "react";
import { CalculSolde } from "../../utils/MargeUtils";
import { BetStatusInProgress } from "../../classes/PlayerBetsModel";
import FormattedAmount from "./FormattedAmount";

const ColumnSolde = (props: { bets: any, payments: any, isBook: boolean }) => {
	const { bets, payments, isBook } = props;
	
	const solde = useMemo(() => {
		return CalculSolde(bets, payments, isBook);
	}, [bets, payments, isBook])

	const nbBetInProgress = useMemo(() => {
		let count = 0;

		if (!Array.isArray(bets)) {
			return 0;
		}

		bets.forEach((bet: any) => {
			if (bet.status == BetStatusInProgress) {
				count++;
			}
		})

		return count;
	}, [bets]);

	return (
		<Box width={"100%"}>
			<Box display="flex" justifyContent={"space-between"} width={"100%"}>
				<Box>Solde</Box> <Box><FormattedAmount amount={solde.solde} chip gain  /></Box>
			</Box>
			{
				solde.bet_amount_inprogress != 0 &&
				<Box display="flex" justifyContent={"space-between"} width={"100%"}>
					<Box>En cours :</Box>
					<Box><FormattedAmount amount={solde.bet_amount_inprogress} chip gain /></Box>
				</Box>
			}
			<Box display="flex" justifyContent={"space-between"} width={"100%"}>
				<Box>Nb en cours :</Box>
				<Box><FormattedAmount amount={nbBetInProgress} chip symbol=" " gain /></Box>
			</Box>
		</Box >
	)
}

export default ColumnSolde;