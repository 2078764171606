import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { AuthState, AuthorizationsState, CurrentUserState } from "../states/AuthState";
import { DefaultRoutes } from "./Routes";
import { Routes as CustomRoutes } from "../custom/Routes";

export interface SectionItem {
    index: string,
    title: string,
    //menuIcon?: JSX.Element,
    children: RouteItem[]
}

export interface RouteItem {
    title: string,
    index: string,
    path: string,
    element: () => JSX.Element,
    menuVisible: boolean,
    menuIcon?: JSX.Element,
    enabled?: boolean,
    authorization: {
        controller: string,
        action: string
    } | null
}

const formatStringToCamelCase = (str: string, upperCaseFirstLetter: boolean) => {
    const words = str.split('-');

    const capitalizedWords = words.map((word, index) => {
        if (index === 0) {
            return (upperCaseFirstLetter ? word.charAt(0).toUpperCase() : word.charAt(0)) + word.slice(1);
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizedWords.join('');
}

export const useAppRoutes = () => {
    const isAuth = useRecoilValue(AuthState);
    const autorizations = useRecoilValue(AuthorizationsState);
    const currentUser = useRecoilValue(CurrentUserState);

    const routeIsOk = useCallback((route: RouteItem) => {
        //Administrateur
        if (currentUser?.user_group_id === 1) {
            return true;
        }

        if (!autorizations) {
            return false;
        }

        if ( route.authorization && typeof(route.authorization) === 'object' ) {
            const _controller = route.authorization.controller;
            const _action = route.authorization.action;
            if (autorizations && autorizations[_controller] && Array.isArray(autorizations[_controller]) && autorizations[_controller].length > 0) {
                const actionExist = autorizations[_controller].find(v => v === _action);
                if (actionExist) {
                    return true;
                }
            }
            return false;
        }else{
            return true;
        }
    }, [autorizations, currentUser]);

    const routes: SectionItem[] = useMemo(() => {

        if (!isAuth || !currentUser) { return []; }

        let _routes: SectionItem[] = [];

        if (CustomRoutes.length > 0) {
            _routes = CustomRoutes;
        } else {
            _routes = DefaultRoutes;
        }

        _routes.forEach(s => {
            s.children.forEach(r => {
                r.enabled = routeIsOk(r);
            });
        })
        return _routes;
    }, [isAuth, currentUser, routeIsOk]);
    return { routes };
}