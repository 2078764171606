import { Box, Button, FormControl, FormHelperText, Grid, IconButton, Tooltip } from "@mui/material";
import { IAncestorInput, useField } from "react-recoil-form";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface IFieldProps {
    name: string;
    ancestors?: IAncestorInput[];
    label?: string;
    required?: boolean;
    disabled?: boolean;
    type?: "datetime" | "date";
    buttonRefresh?: boolean;
    size?: 'small' | 'medium';
    minDate?: string | Date | Dayjs,
    maxDate?: string | Date | Dayjs
}



const isDateValid = (value: any) => {
    return !(!value || (typeof value && Object.keys(value).length === 0));
}

const dateFormatDisplay = "DD/MM/YYYY";
const datetimeFormatDisplay = "DD/MM/YYYY HH:mm";
const datetimeFormat = "YYYY-MM-DDTHH:mm:00Z";
const dateFormat = "YYYY-MM-DD";

export const getDateNow = () => {
    return dayjs(new Date()).tz().format(dateFormat);
}

export const getDatetimeNow = () => {
    return dayjs(new Date()).tz().format(datetimeFormat);
}

const DateField = (props: IFieldProps) => {
    const { name, ancestors, label, required, disabled, type, buttonRefresh, size, minDate, maxDate } = props;


    const DateTimeFormat = useMemo(() => {
        return (type === "date") ? dateFormat : datetimeFormat;
    }, [type]);

    const validate = useCallback((value: any) => {
        if (required && !isDateValid(value)) {
            return `Le champ ${label} est requis`;
        }

        if (value && value == "Invalid Date") {
            return `La date est invalide`;
        }

        /* Ce code n'est pas viable car la fonction ne se recrée pas dans useField => validate, donc si minDate ou maxDate change, le test ne fonctionnera pas
        if(minDate ){
            const minDateF = dayjs(minDate);
            const valueF = dayjs(value);
            if( minDateF.isAfter(valueF) ){
                return "La date doit être supériere à "+minDateF.tz().format(type === "date" ? dateFormatDisplay : datetimeFormatDisplay);
            } 
        }

        if(maxDate ){
            const maxDateF = dayjs(maxDate);
            const valueF = dayjs(value);
            if( valueF.isAfter(maxDateF) ){
                return "La date doit être inférieure à "+maxDateF.tz().format(type === "date" ? dateFormatDisplay : datetimeFormatDisplay);
            } 
        }
        */

    }, [required, label])

    const { fieldValue, setFieldValue, error } = useField({
        ancestors,
        name,
        validate: validate
    });

    const valueD = useMemo(() => {
        return fieldValue ? dayjs(fieldValue).tz() : undefined;
    }, [fieldValue]);

    const handleSetCurrentDate = useCallback(() => {
        setFieldValue(dayjs(new Date()).tz().format(DateTimeFormat));
    }, [setFieldValue, DateTimeFormat]);

    const onChange = useCallback((v: any) => {
        setFieldValue(v ? v.format(DateTimeFormat) : null);
    }, [DateTimeFormat])

    const dateTimePickerPadding = useMemo(() => {
        return (size === "small" ? "8.5px 14px 8.5px 14px" : undefined)
    }, [size])

    const inputLabel = useMemo(() => {
        return `${label} ${(required ? "*" : "")}`
    }, [label])

    return (
        <FormControl error={!!error} fullWidth size={size}>
            <Grid container display="flex" alignItems={"center"}>
                <Grid item >
                    {type === "date" ?
                        <DatePicker
                            sx={{ "& .MuiInputBase-input": { padding: "8.5px 14px 8.5px 14px" } }}
                            label={inputLabel}
                            disabled={disabled}
                            value={valueD}
                            onChange={onChange}
                        />
                        :
                        <DateTimePicker

                            sx={{
                                "& .MuiInputBase-input": {
                                    padding: dateTimePickerPadding,
                                }
                            }}
                            label={inputLabel}
                            disabled={disabled}
                            value={valueD}
                            onChange={onChange}
                            minDate={minDate ? dayjs(minDate).tz() : undefined}
                            maxDate={maxDate ? dayjs(maxDate).tz() : undefined}
                        />
                    }

                </Grid>
                {buttonRefresh &&
                    <Grid item ml={2}>
                        {/* TODO Trad */}
                        <Tooltip title="Affecter la date et heure d'aujourd'hui">
                            <IconButton onClick={handleSetCurrentDate}>
                                <AccessTimeIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
            {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
        </FormControl>
    );
}

export default DateField;