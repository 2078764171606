import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { IAncestorInput, useFieldArray, useFieldArrayColumnWatch } from "react-recoil-form";
import AutocompleteField, { AutocompleteOption } from "./AutocompleteField";
import InputField from "./InputField";
import DeleteIcon from "@mui/icons-material/Delete";
import NumberField from "./NumberField";
import { ConfirmModalState } from "../../../states/ModalState";
import { useSetRecoilState } from "recoil";
import SelectField, { SelectOptionsProps } from "./SelectField";
import DateField from "./DateField";

interface AddEditFormListProps {
    fields: AddEditFormListField[],
    hiddenFields: {
        add: string[],
        edit: string[]
    }
    entity: string,
    newEntity?: string,
    title: string,
    defaultItem: any
    disabled?: boolean,
    buttonAdd?: {
        title: string
    }
}



interface AddEditFormListField {
    name: string,
    xs: number,
    type: "text" | "number" | "autocomplete" | "select" | "date" | "custom",
    customElement?: (ancestors: IAncestorInput[]) => JSX.Element,
    label?: string,
    autocompleteOptions?: AutocompleteOption[],
    selectOptions?: SelectOptionsProps[],
    required?: boolean,
    disabled?: boolean,
    maxDecimals? : number
}

interface AddEditFormListItemProps {
    fields: (AddEditFormListField)[],
    hiddenFields?: string[],
    prefixeName: string,
    deleteEvent: () => void,
    id: number,
    disabled?: boolean,
    isNew? :boolean
}

const AddEditFormListItem = (props: AddEditFormListItemProps) => {
    const { fields, hiddenFields, prefixeName, deleteEvent, id, disabled, isNew } = props;

    const setConfirmModalState = useSetRecoilState(ConfirmModalState);

    const deleteConfirmEvent = useCallback(() =>
        setConfirmModalState({
            open: true,
            title: "Suppression",
            desc: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
            validEvent: () => {
                deleteEvent();
            }
        }), [deleteEvent, setConfirmModalState]);

    return (
        <>
            {hiddenFields?.map((hiddenField, hiddenFieldKey) => (
                <InputField key={hiddenFieldKey} ancestors={[{ name: prefixeName, rowId: id }]} name={`${prefixeName}.${hiddenField}`} type="hidden" />
            ))}
            {fields.map((field, fieldKey) => (
                <Grid key={`${id}_${field.name}`} item xs={field.xs} sx={ isNew ? { backgroundColor: "cornsilk"} : undefined}>

                    {field.type === "custom" && field.customElement && field.customElement([{ name: prefixeName, rowId: id }])}

                    {field.type === "autocomplete" &&
                        <AutocompleteField
                            //size="small"
                            ancestors={[{ name: prefixeName, rowId: id }]}
                            options={field.autocompleteOptions ?? []}
                            name={field.name}
                            required={field.required}
                            label={field.label}
                            disabled={field.disabled || disabled}
                        />
                    }

                    {field.type === "select" &&
                        <SelectField
                            size="small"
                            ancestors={[{ name: prefixeName, rowId: id }]}
                            options={field.selectOptions ?? []}
                            name={field.name}
                            required={field.required}
                            label={field.label}
                            disabled={field.disabled || disabled}
                        />
                    }

                    {field.type === "number" &&
                        <NumberField
                            size="small"
                            ancestors={[{ name: prefixeName, rowId: id }]}
                            name={`${field.name}`}
                            required={field.required}
                            label={field.label}
                            disabled={field.disabled || disabled}
                            maxDecimals={field.maxDecimals}
                        />
                    }

                    {(field.type === "text") &&
                        <InputField
                            size="small"
                            ancestors={[{ name: prefixeName, rowId: id }]}
                            name={`${field.name}`}
                            required={field.required}
                            label={field.label}
                            disabled={field.disabled || disabled}
                            type={"text"}
                        />
                    }

                    {(field.type === "date") &&
                        <DateField
                            size="small"
                            ancestors={[{ name: prefixeName, rowId: id }]}
                            name={`${field.name}`}
                            required={field.required}
                            label={field.label}
                            disabled={field.disabled || disabled}
                            type={"date"}
                        />
                    }
                </Grid>
            ))}
            <Grid item xs={2}  sx={ isNew ? { backgroundColor: "cornsilk"} : undefined}>
                {!disabled &&
                    <IconButton color="error" onClick={() => deleteConfirmEvent()}><DeleteIcon /></IconButton>
                }
            </Grid>
            <Grid xs={12} pt={2}  sx={ isNew ? { backgroundColor: "cornsilk"} : undefined}>
                <Divider />
            </Grid>
        </>
    )
}

const AddEditFormList = (props: AddEditFormListProps) => {

    const { entity, newEntity, fields, hiddenFields, title, buttonAdd, defaultItem, disabled } = props;

    const fieldNames = useMemo(() => {
        return fields.map((field) => field.name);
    }, [fields]);

    const _newEntity = useMemo(() => {
        if (newEntity) { return newEntity; }
        const words = entity.split('_');
        const transformedWords = words.map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return 'new' + transformedWords.join('');
    }, [entity, newEntity])

    const entityListField = useFieldArray({ name: entity, fieldNames: [...fieldNames, ...hiddenFields.edit], });
    const newEntityListField = useFieldArray({ name: _newEntity, fieldNames: [...fieldNames, ...hiddenFields.add] });

    return (
        <Box display={"flex"} flexDirection={"column"} >

            {!disabled && buttonAdd &&
                <Box mb={4}>
                    <Button size="small" variant="contained" color="primary" onClick={() => newEntityListField.append(defaultItem)}>{buttonAdd.title}</Button>
                </Box>
            }


            <Grid container spacing={2} display={"flex"} alignItems={"center"}>
                <>

                    {!disabled && newEntityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <AddEditFormListItem
                            key={rowId}
                            id={rowId}
                            prefixeName={`${_newEntity}`}
                            fields={fields}
                            hiddenFields={hiddenFields.add}
                            deleteEvent={() => newEntityListField.remove(rowIndex)}
                            isNew
                        />
                    ))}

                    {entityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <AddEditFormListItem
                            key={rowId}
                            id={rowId}
                            prefixeName={`${entity}`}
                            fields={fields}
                            hiddenFields={hiddenFields.edit}
                            deleteEvent={() => entityListField.remove(rowIndex)}
                            disabled={disabled}
                        />
                    ))}

                </>
            </Grid>
        </Box>
    )

}

export default AddEditFormList;