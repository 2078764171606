import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { CalculSolde } from "../../utils/MargeUtils";
import { Alert, Box, Button, Card, Container, Divider, Grid, Paper, Tab, Tabs } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import FormattedAmount from "../common/FormattedAmount";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import ListSearchServer from "../../../components/common/list/ListSearchServer";
import { ListModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import { BetStatusInProgress } from "../../classes/PlayerBetsModel";
import { TabContext, TabList } from "@mui/lab";
import MarginListSectionMargin from "./MarginListSectionMargin";
import MarginListSectionMatch from "./MarginListSectionMatchs";
import { useListCoreFilters } from "../../../states/ListState";
import { useLocation } from "react-router-dom";

export interface MarginListModel extends ListModelCore {
	books: any[],
	players: any[],
	agents: any[],
	margins: any[]
}

const MarginList = () => {
	const [search, setSearch] = useState<ListSearchModelCore | undefined>(undefined);
	const [get] = useGetListCore<MarginListModel>(`/project-bets/bets/margins/index`);

	const [playerBets, setPlayerBets] = useState<any[]>([]);
	const [players, setPlayers] = useState<any[]>([]);
	const [books, setBooks] = useState<any[]>([]);
	const [agents, setAgents] = useState<any[]>([]);

	const [loading, setLoading] = useState(false);


	const refreshWithSearch = useCallback((search: ListSearchModelCore | undefined, csv?: boolean, pdf?: boolean, xls?: boolean, reinit?: boolean) => {
        setLoading(true); // Set loading to true before fetching data
		get(5000, 0, search, csv, pdf)
			.then((res) => {
				if (res.status === ReqStatus.SUCCESS && res.data) {
					setBooks(res.data.books ?? []);
					setPlayers(res.data.players ?? []);
					setAgents(res.data.agents ?? []);
					setPlayerBets(res.data.margins ?? []);
					setSearch(res.data.search);
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false); // Set loading to false after the data has been fetched
			});
    }, [get]);

    const refresh = useCallback((csv?: boolean, pdf?: boolean, xls?: boolean, reinit?: boolean) => {
        refreshWithSearch(search, csv, pdf, xls, reinit);
    }, [search, refreshWithSearch]);

	useEffect(() => {
		refresh();
	}, []);

	const nbBetsInProgress = useMemo(() => {
		let player_bet_count = 0;
		let book_bet_count = 0;
		playerBets.forEach((b) => {
			if (b.status === BetStatusInProgress) {
				player_bet_count++;
				b.book_bets?.forEach((bb: any) => {
					if (b.status === BetStatusInProgress) {
						book_bet_count++;
					}
				});
			}
		});
		return {
			player: player_bet_count,
			book: book_bet_count
		};
	}, [playerBets])

	const [tabId, setTabId] = useState(1);

	return (
		<Box height={"100%"} overflow={"auto"} >
			<Box maxWidth="md" mt={2} mx="auto">
				{search &&
					<ListSearchServer
						search={search}
						setSearch={setSearch}
						refresh={refresh} 
						refreshWithSearch={refreshWithSearch}
					/>
				}
			</Box>
			{!loading &&
				<Container maxWidth="md">

					<Box mb={1}>
						<Tabs variant="scrollable" value={tabId} onChange={(e, v) => setTabId(+v)} >
							<Tab label="Marges" value={1} />
							<Tab label="Details marges" value={2} />
							<Tab label="Détails matchs" value={3} />
						</Tabs>
					</Box>

					{nbBetsInProgress.player > 0 &&
						<Box mb={1}>
							<Alert color="error" icon={false}>Il y a {nbBetsInProgress.player} jeux <strong>joueurs</strong> en cours sur la période</Alert>
						</Box>
					}
					{nbBetsInProgress.book > 0 &&
						<Box mb={1}>
							<Alert color="error" icon={false}>Il y a {nbBetsInProgress.book} jeux <strong>books</strong> en cours sur la période</Alert>
						</Box>
					}

					{(tabId == 1 || tabId == 2) &&
						<MarginListSectionMargin
							agents={agents}
							books={books}
							playerBets={playerBets}
							players={players}
							simpleDisplay={tabId == 1}
						/>
					}

					{tabId == 3 &&
						<MarginListSectionMatch
							agents={agents}
							books={books}
							playerBets={playerBets}
							players={players}
						/>
					}
				</Container>
			}
		</Box >
	)
}

export default MarginList;