import { FormControl, MenuItem, FormHelperText, TextField, Select, InputLabel } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { IAncestorInput, useField } from "react-recoil-form";

export interface SelectOptionsProps {
    value: string | number;
    label: string;
    formatMessage?: boolean;
}

interface IFieldProps {
    name: string;
    ancestors?: IAncestorInput[];
    label?: string;
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    options: SelectOptionsProps[];
    handleChange?: (value?: any) => void;
    optionType?: "string" | "number",
    noChoice?: boolean,
    noChoiceLabel?: string,
    size?: "small" | "medium"
}

const SelectField = (props: IFieldProps) => {
    const { size, name, ancestors, label, required, disabled, options, handleChange, optionType, multiple, noChoice, noChoiceLabel } = props;
    const intl = useIntl();
    const { fieldValue, setFieldValue, error } = useField({
        ancestors,
        name,
        validate: (value) => {
            if (value === 0) { return; }
            if (required && !value) {
                return `Le champ ${label} est requis`;
            }
        }
    });

    const getLabelOfOption = useCallback((label: string, formatMessage?: boolean) => {
        return (formatMessage === true) ? intl.formatMessage({ id: label }) : label;
    }, [intl]);

    const _value = useMemo(() => {
        if (!multiple) {
            return fieldValue === 0 ? fieldValue : (fieldValue ? fieldValue : "");
        } else {
            return fieldValue ?? [];
        }
    }, [multiple, fieldValue]);

    return (
        <FormControl error={!!error} size={size} variant="outlined" fullWidth>
            <InputLabel>{label} {required && "*"}</InputLabel>
            <Select
                disabled={disabled}
                label={label}
                value={_value}
                size={size}
                onChange={(e) => {
                    if (!multiple) {
                        var v = (optionType === "number") ? +e.target.value : e.target.value;
                        setFieldValue(v);
                        handleChange?.(v);
                    } else {
                        setFieldValue(e.target.value);
                        handleChange?.(e.target.value);
                    }
                }}
                required={required}
                multiple={multiple}
            >
                {noChoice &&
                    <MenuItem value={0}>{noChoiceLabel ? noChoiceLabel : "Aucun"}</MenuItem>
                }
                {options.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                        {getLabelOfOption(option.label, option.formatMessage)}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;
