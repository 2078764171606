import {
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  SxProps,
  Theme
} from "@mui/material";
import { IAncestorInput, useField } from "react-recoil-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { min } from "moment";

interface IFieldProps {
  name: string;
  ancestors?: IAncestorInput[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
  endAdornment? :JSX.Element;
  endAdornmentSx? : SxProps<Theme>
  textAlign? : "left" | "center" | "right"
  minDecimals? : number,
  maxDecimals? : number,
  size? : 'small' | 'medium',
  blurDisabled?: boolean
}

export default function NumberField(props: IFieldProps) {
  const { name, ancestors, label, required, disabled, endAdornment, endAdornmentSx, textAlign, minDecimals, maxDecimals, size, blurDisabled } = props;
  const { fieldValue, setFieldValue, error } = useField({
    ancestors,
    name,
    validate: (value) => {
      if (required && (value === undefined || value === null)) {
        return `Le champ ${label} est requis`;
      }
    }
  });

  const value = useMemo(() => {
    if (isNaN(fieldValue)) {
      return "";
    }
    return parseFloat(fieldValue);
  }, [fieldValue, minDecimals])

  const [internalValue, setInternalValue] = useState<string | number | undefined>("");
  useEffect(() => {
    if (value === "") { setInternalValue(""); return; }
    if (minDecimals) {
      //On affiche la valeur avec le nombre minimal de décimales attendu
      setInternalValue((+value).toFixed(minDecimals));
    } else {
      //on ajuste la valeur avec le nombre maxDecimals attendu
      setInternalValue(+(+value).toFixed(maxDecimals ?? 2));
    }

  }, [value])

  const updateFieldValue = useCallback((internalValue: string | number | undefined) => {
    if ( !internalValue && internalValue !== 0 ) {
      setFieldValue(undefined);
    } else {
      if (minDecimals) {
        //On affiche la valeur avec le nombre minimal de décimales attendu
        setFieldValue(+((+internalValue).toFixed(minDecimals)));
      } else {
        //on ajuste la valeur avec le nombre maxDecimals attendu
        setFieldValue(+((+internalValue).toFixed(maxDecimals ?? 2)));
      }
    }
  },[minDecimals, maxDecimals]);

  useEffect(() => {
    if(blurDisabled){
      updateFieldValue(internalValue);
    }
  },[internalValue, blurDisabled])

  return (
    <FormControl error={!!error} fullWidth>
      <TextField
        id={name}
        type={"number"}
        value={internalValue}
        onChange={(e) => {
          setInternalValue(e.target.value ? +(e.target.value) : undefined);
        }}
        onBlur={() => {
          if (blurDisabled) {
            return;
          } else {
            updateFieldValue(internalValue);
          }
        }}
        disabled={disabled ?? false}
        label={label}
        required={required}
        variant={"outlined"}
        size={size}
        InputProps={{
          inputProps: {
            style: {
              textAlign: textAlign ?? "left"
            },
          },
          endAdornment: (endAdornment ?
            <InputAdornment position="end" sx={endAdornmentSx} >
              {endAdornment}
            </InputAdornment>
            : <></>
          ),
        }}
      />
      {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
    </FormControl>
  );
}
