import { atom } from 'recoil';
import { UserModel } from '../classes/users/UserModel';

export const AuthState = atom<boolean>({
    key: 'AuthKey',
    default: false
});

export const CsrfTokenState = atom<string>({
    key: 'CsrfTokenKey',
    default: ""
});

export const CurrentUserState = atom<UserModel | null>({
    key: 'CurrentUserKey',
    default: null
});


export const AuthorizationsState = atom<{[controller: string]: string[]} | null>({
    key: 'AuthorizationsKey',
    default: null
});