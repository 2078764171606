import { useCallback } from "react";
import { useApi, GenericResponse, ReqStatus } from "./core/UseApi";
import { UserGroupModel } from "../classes/user-groups/UserGroupModel";

export const useGetUserGroupsList = function (): [() => Promise<GenericResponse<{ userGroups: UserGroupModel[]}>>, ReqStatus] {
    const [callCore, status] = useApi<{ userGroups: UserGroupModel[]}, undefined>(
        {
            route: 'user-groups/index',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);
    return [call, status];
};

interface GetUserGroupResponse{
    userGroup: UserGroupModel,
    redirectionsList: string[]
}
export const useGetUserGroups = function (): [(id: number) => Promise<GenericResponse<GetUserGroupResponse>>, ReqStatus] {
    const [callCore, status] = useApi<GetUserGroupResponse, {id: number}>(
        {
            route: 'user-groups/add-edit',
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id: number) => {
        return callCore( undefined, `/${id}` );
    }, [callCore]);
    return [call, status];
};

export const useAddEditUserGroup = function (): [(data: UserGroupModel) => Promise<GenericResponse<{}>>, ReqStatus] {
    const [callCore, status] = useApi<{}, UserGroupModel>(
        {
            route: 'user-groups/add-edit',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((data: UserGroupModel) => {
        return callCore(data, data.id ? `/${data.id}` : '');
    }, [callCore]);
    return [call, status];
};

export const useDeleteUserGroup = function (): [(id: number) => Promise<GenericResponse<{}>>, ReqStatus] {
    const [callCore, status] = useApi<{},  {id: number}>(
        {
            route: 'user-groups/delete',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id: number) => {
        return callCore( undefined, `/${id}` );
    }, [callCore]);
    return [call, status];
};