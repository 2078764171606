import { ListModelCore } from "../../classes/list/ListModel";
import { AutocompleteOption } from "../../components/common/form/AutocompleteField";
import { SelectOptionsProps } from "../../components/common/form/SelectField";

export const PlayerBetsRoute = "project-bets/bets/player-bets";

export interface PlayerBetListModel extends ListModelCore{
    playerBets: any[]
}

export const BetStatusInProgress = 1;
export const BetStatusCancel = 2;
export const BetStatusLose = 3;
export const BetStatusWin = 4;


export const BetStatusOptions : SelectOptionsProps[] = [
    { value: BetStatusInProgress, label: "En cours"},
    { value: BetStatusWin, label: "Gagné"},
    { value: BetStatusLose, label: "Perdu"},
    { value: BetStatusCancel, label: "**Annulé**"}
]

export const GetStatutLabel = (status: number) => {
    return BetStatusOptions.find(s => s.value === status)?.label;
}

export const GetStatusColor = (status: number) => {
    if(status == BetStatusInProgress){ return "warning"; }
    if(status == BetStatusCancel){ return "primary"; }
    if(status == BetStatusLose){ return "error"; }
    if(status == BetStatusWin){ return "success"; }
    return "default";
}