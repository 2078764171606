import { Autocomplete, TextField } from "@mui/material";
import { AutocompleteOption } from "../form/AutocompleteField";

interface AutocompleteCoreProps{
    value: AutocompleteOption | null
    onChange: ( newValue: AutocompleteOption | null) => void,
    options: AutocompleteOption[],
    required? :boolean,
    disabled? :boolean,
    label? : string,
    size?: "small" | "medium"
}
const AutocompleteCore = (props: AutocompleteCoreProps) => {

    const { value, onChange, options, required, label, disabled, size} = props;

    return (

        <Autocomplete<AutocompleteOption>
            size={size}
            value={value}
            onChange={(event, newValue) => {
                if(disabled){ return; }
                onChange(newValue);
            }}
            getOptionLabel={(option) => option?.label ?? ""}
            options={options}
            disabled={disabled ?? false}       
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    required={required}
                    disabled={disabled ?? false}
                />
            )}
        />
    )
}

export default AutocompleteCore;