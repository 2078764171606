import { atom } from 'recoil';

export const MenuIndexState = atom<{ index: string, subIndex: string, title: string}>({
    key: 'MenuIndexKey',
    default: { index: "", subIndex: "", title: ""}
});

export const MenuVisibleState = atom<boolean>({
    key: 'MenuVisibleKey',
    default: false
});

export const NavigationBlockedState = atom<boolean>({
    key: 'NavigationBlockedKey',
    default: false
});

export const LogoPathState = atom<string>({
    key: 'LogoPathKey',
    default: ''
})

export const CompanyNameState = atom<string>({
    key: 'CompanyNameKey',
    default: ''
})

export const NavigateState = atom<string>({
    key: "NavigateKey",
    default: ""
})