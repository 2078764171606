import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useEffect, useState } from "react";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Grid } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import AddEditFormList from "../../../components/common/form/AddEditFormList";
import { useRecoilValue } from "recoil";
import { CurrentUserState } from "../../../states/AuthState";
import BetsDetails from "../common/BetsDetails";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { createOptionsForSelect } from "../../../utils/DataUtils";
import { getDateNow } from "../../../components/common/form/DateField";
import SoldeDetails from "../common/SoldeDetails";

const BookView = () => {
    const listRoute = "/project-bets/bets/books";
    const { getData, addEditData } = useAddEditCore<any, { book: any, agents: any, players: any }>(`${listRoute}/add-edit`);

    const [agents, setAgents] = useState<SelectOptionsProps[]>([]);
    const [players, setPlayers] = useState<SelectOptionsProps[]>([]);
    const [bets, setBets] = useState<any[]>([]);
    const [payments, setPayments] = useState<any[]>([]);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {

        const _request = JSON.parse(JSON.stringify(request));

        if (!_request.book_payments || _request.book_payments.length === 0) {
            delete _request.book_payments;
        }
        if (!_request.newBookPayments || _request.newBookPayments.length === 0) {
            delete _request.newBookPayments;
        }

        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit });

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _data = res.data.book;
                    setBets(_data.book_bets ?? []);
                    setPayments(_data.book_payments ?? []);

                    resetInitialValues({
                        name: _data.name,
                        description: _data.description,
                        agent_id: _data.agent_id,
                        book_payments: _data.book_payments && _data.book_payments.length > 0 ? _data.book_payments.map((p: any) => {
                            return {
                                id: p.id,
                                book_id: p.book_id,
                                name: p.name,
                                amount: p.amount,
                                date: p.date
                            };
                        }) : undefined,
                        addElements: "1"
                    });
                } else {
                    resetInitialValues({
                        name: '',
                        description: '',
                        addElements: "1"
                    });
                }

                setAgents(createOptionsForSelect(res.data.agents));
                setPlayers(createOptionsForSelect(res.data.agents));
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    const currentUser = useRecoilValue(CurrentUserState);

    return (
        <AddEditForm formId="bookForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un book"} maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputField size="small" name="name" label="Nom" type="text" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField size="small" name="agent_id" label="Agent" options={agents} required />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaField size="small" name="description" label="Description" maxRows={4} />
                    </Grid>

                </Grid>

                {id && <SoldeDetails bets={bets} payments={payments} isBook={true} />}
            </AddEditFormCard>



            {id && <>
                <BetsDetails bets={bets} isBook={true} players={players} books={[]} />

                <AddEditFormCard title={`Paiements (${payments.length})`} maxWidth="md" withExpand={true} isExpand={true}>
                    <AddEditFormList
                        fields={[{
                            name: "name",
                            type: "text",
                            label: "Référence",
                            xs: 12,
                            required: true
                        },
                        {
                            name: "amount",
                            type: "number",
                            label: "Montant",
                            required: true,
                            xs: 5,
                        },
                        {
                            name: "date",
                            type: "date",
                            label: "Date",
                            required: true,
                            xs: 5,
                        }
                        ]}
                        hiddenFields={{
                            add: [],
                            edit: ["id", "book_id"]
                        }}
                        entity={"book_payments"}
                        title={"Paiements"}
                        buttonAdd={{ title: "Ajouter un paiement" }}
                        defaultItem={{
                            user_id: currentUser?.id ?? 0,
                            date: getDateNow()
                        }}
                    />
                </AddEditFormCard>

            </>}

        </AddEditForm>
    )
}

export default BookView;