import { GridColDef } from "@mui/x-data-grid";
import { PlayerListModel } from "../../classes/PlayerModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { BetStatusInProgress, GetStatutLabel, PlayerBetListModel, PlayerBetsRoute } from "../../classes/PlayerBetsModel";
import { useEffect, useState } from "react";
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import BetStatusChip from "../common/BetStatusChip";
import { datetimeFormat } from "../../../utils/DateUtils";
import { CreateColumnAvatar } from "../../../components/common/list/column/ColumnAvatar";
import FormattedAmount from "../common/FormattedAmount";
import { CalculCommission, CalculGain } from "../../utils/MargeUtils";
import { useNavigate } from "react-router-dom";


const useColumns = () => {
    const navigate = useNavigate();

    return [
        {
            field: 'id',
            headerName: "Id",
            editable: false,
            filterable: false,
            width: 20,
            renderCell: ({ row }: any) => (
                <IconButton size="small" onClick={() => navigate(`/${PlayerBetsRoute}/add-edit/${row.id}`)}>
                    <Typography fontSize={14}>{row.id}</Typography>
                </IconButton>
            )
        },
        {
            field: 'bet',
            headerName: "Match",
            editable: false,
            filterable: false,
            width: 160,
            renderCell: ({ row }: any) => (
                <Box>
                    <Box
                        sx={{
                            width: 150,
                            wordWrap: 'break',
                            whiteSpace: 'normal',
                            overflow: 'hidden',         // Cache le contenu débordant
                            textOverflow: 'ellipsis',   // Ajoute des points de suspension
                            display: '-webkit-box',     // Utilise le modèle de boîte flexible de WebKit
                            WebkitLineClamp: 2,         // Limite le texte à 2 lignes
                            WebkitBoxOrient: 'vertical' // Oriente le contenu de la boîte verticalement
                        }}
                    >
                        {row.name ?? ""}
                    </Box>

                    <Box>{datetimeFormat(row.date)}</Box>
                    <Box><FormattedAmount amount={row.com} chip /></Box>

                </Box>
            )
        },
        {
            field: 'player',
            headerName: "Joueur",
            editable: false,
            filterable: false,
            width: 110,
            renderCell: ({ row }: any) => (

                <Box>
                    <Box>{row.player?.name}</Box>
                    <Box display="flex">
                        <BetStatusChip status={row.status} />
                    </Box>
                    <Box><FormattedAmount amount={row.gain} gain chip /></Box>
                    <Box><FormattedAmount amount={row.amount} /> * {parseFloat(row.cote).toString()}</Box>
                </Box>
            )
        },
        {
            field: 'book',
            headerName: "Book(s)",
            editable: false,
            filterable: false,
            flex:1,
            minWidth: 500,
            renderCell: ({ row }: any) => (
                <Box width={100 * (row.book_bets?.length ?? 1)} display="flex">
                    {row.book_bets?.map((b: any, key: number) => {
                        return (
                            <Box mr={2}>
                                <Box key={b.book_id}>{b.book.name}</Box>
                                <Box><BetStatusChip status={b.status} /></Box>
                                <Box>
                                    <FormattedAmount amount={b.gain} gain chip />
                                </Box>
                                <Box><FormattedAmount amount={b.amount} /> * {parseFloat(b.cote).toString()}</Box>
                            </Box>
                        )
                    }
                    )}
                </Box>
            )
        },
    ]
}

export const PlayerBetListInProgress = () => {
    return <PlayerBetList onlyInProgress />
}

const PlayerBetList = (props: { onlyInProgress?: boolean }) => {

    const [get] = useGetListCore<PlayerBetListModel>(`/${PlayerBetsRoute}/index${props.onlyInProgress ? '?only-in-progress=1' : ''}`);

    const columns = useColumns();

    return (
        <ListCore<PlayerBetListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.playerBets}
            getRowId={(row) => row.id}
            columns={columns}
            paginationOptionDefault={100}
            actions={{
                defaultRoute: PlayerBetsRoute,
                add: {
                    title: "Ajouter un pari"
                },
                edit: false,
                remove: false,
                minWidth: 1
            }}
            rowHeight={90}
        />
    );
}

export default PlayerBetList;