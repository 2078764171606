import { Box, Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, useMediaQuery } from "@mui/material";
import { useAppRoutes, RouteItem, SectionItem } from "./AppRoutes";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MenuIndexState, MenuVisibleState } from "../states/MenuState";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
const MenuSection = (props: { section: SectionItem }) => {

    const { section } = props;
    const menuIndex = useRecoilValue(MenuIndexState);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    
    const setMenuVisible = useSetRecoilState(MenuVisibleState);

    useEffect(() => {
        if (menuIndex.index === section.index) {
            setOpen(true);
        }else{
            setOpen(false);
        }
    }, [menuIndex.index, section.index])

    const displayChildren = useCallback(() => {
        return (
            section.children.map((route, index) =>
            (route.menuVisible && route.enabled &&
                <ListItem
                    key={index}
                    style={{ borderLeft: "4px solid " + ((section.index === menuIndex.index && route.index === menuIndex.subIndex) ? "blue" : "transparent") }}
                    onClick={() => { navigate(route.path); setMenuVisible(false); }
                    }>
                    <ListItemIcon >
                        {route.menuIcon}
                    </ListItemIcon>
                    <ListItemText sx={{ marginLeft: "-20px" }} primary={route.title} />
                </ListItem>
            ))
        )
    }, [section.children, section.index, navigate, menuIndex]);

    const atLeastOneChildrenEnabled = useMemo(() => {
        return (section.children.find(r => r.enabled && r.menuVisible)) ? true : false;
    }, [section])

    return (
        <>
            {atLeastOneChildrenEnabled &&
                <>
                    <ListItem onClick={() => setOpen(!open)}>
                        <ListItemText primary={section.title} />
                        {!open && <ExpandMoreIcon />}
                        {open && <ExpandLessIcon />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {displayChildren()}
                        </List>
                    </Collapse>
                    <Divider />
                </>
            }
        </>
    )
}


const Menu = () => {
    const menuVisible = useRecoilValue(MenuVisibleState);
    const { routes } = useAppRoutes();
    return (
        <>
            {menuVisible &&
                <Box borderRight={"1px solid lightgray"} height={"calc(100vh - 65px)"} sx={
                    {
                        position: {
                            xs: "absolute",
                            md: "initial"
                        },
                        backgroundColor: 'white',
                        zIndex: {
                            xs: 10,
                            md: 'inherits'
                        },
                    }
                }>
                    {/* sx={{ position: 'absolute', backgroundColor: 'white', zIndex : 10 }} */}
                    {/* height={"calc(100vh - 65px)"} position="absolute" */}
                    <Paper sx={{ height: "inherit", backgroundColor: 'white', minWidth: "200px", overflowY: "auto" }}>
                        <List disablePadding >
                            {routes.map((section, key) => (
                                <MenuSection key={key} section={section} />
                            ))}
                        </List >
                    </Paper>
                </Box >
            }
        </>
    );
}

export default Menu;