import { BetStatusCancel, BetStatusInProgress, BetStatusLose, BetStatusWin } from "../classes/PlayerBetsModel";

export const CalculCommission = ( bet : any ) => { 

	if(! bet){ return 0; }

	if(bet.status === BetStatusInProgress){
		return 0;
	}
	
	let _com = CalculGain(bet, false);
	if( bet.book_bets && Array.isArray(bet.book_bets)){
		bet.book_bets.forEach(( book_bet: any ) => {
			_com += CalculGain(book_bet, true);
		})
	}

	return _com * (-1);
}

export const CalculGain = ( b: any, isBook: boolean ) => {
	let bet_amount = 0;
	
	if (b.status === BetStatusCancel) {
		return 0;
	}

	if (isBook) {
		if (b.status === BetStatusInProgress) {
			return 0;
		}
		if (b.status === BetStatusLose) {
			bet_amount -= (b.amount * b.cote) - b.amount;
		}
		if (b.status === BetStatusWin) {
			bet_amount += b.amount;
		}
	} else {
		if (b.status === BetStatusInProgress) {
			bet_amount -= b.amount;
		}
		if (b.status === BetStatusLose) {
			bet_amount -= b.amount;
		}

		if (b.status === BetStatusWin) {
			bet_amount += b.amount * b.cote - b.amount;
		}
	}
	return bet_amount;
}

export const CalculSolde = (bets: any, payments: any, isBook: boolean) => {

	let payment_amount = 0;

	payments?.forEach((p: any) => {
		payment_amount += p.amount;
	})

	let marge = 0;


	let bet_amount = 0;
	let bet_amount_inprogress = 0;
	bets?.forEach((b: any) => {
		const _amount = CalculGain(b, isBook);
		if(!isBook){
			marge += CalculCommission(b);
		}
		if(b.status === BetStatusInProgress && ! isBook){
			bet_amount_inprogress += _amount;
		}
		bet_amount += _amount;
	});
	return {
		marge: marge,
		bet_amount: bet_amount,
		payment_amount: payment_amount,
		solde: bet_amount + payment_amount,
		bet_amount_inprogress: bet_amount_inprogress
	};
}