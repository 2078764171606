import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { BillItemModel } from "../../classes/bills/BillModel";

const BillProductsInfo = (props: { items: BillItemModel[] }) => {

	const { items } = props
	return (
		<AddEditFormCard title={'Produits'} withExpand>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Produit</TableCell>
						<TableCell >Référence</TableCell>
						<TableCell >Quantité</TableCell>
						<TableCell >Prix HT</TableCell>
						<TableCell >Montant HT</TableCell>
						<TableCell >Montant TTC</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item, key) => (
						<TableRow key={`bill_product_{$key}`}>
							<TableCell>{item.name}</TableCell>
							<TableCell>{item.reference}</TableCell>
							<TableCell>{item.quantity}</TableCell>
							<TableCell>{item.unit_tax_excl}</TableCell>
							<TableCell>{item.total_tax_excl}</TableCell>
							<TableCell>{item.total_tax_incl}</TableCell>
						</TableRow>
					))}

				</TableBody>
			</Table>
		</AddEditFormCard >
	)
};

export default BillProductsInfo;