import { Outlet, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements, useLocation, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { RouteItem, useAppRoutes } from "./AppRoutes";
import { useEffect, useMemo, useState, useCallback, PropsWithChildren } from "react";
import { Box, Button } from "@mui/material";
import LoginForm from "../components/login/LoginForm";
import NewPasswordForm from "../components/login/NewPasswordForm";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AuthState, AuthorizationsState, CsrfTokenState, CurrentUserState } from "../states/AuthState";
import { useIsAuth } from "../stores/AuthStore";
import { ReqStatus } from "../stores/core/UseApi";
import AppLoader from "./Loader";
import { useGetCompanyInfo } from "../stores/SettingsStore";
import { CompanyNameState, LogoPathState, MenuIndexState, NavigateState } from "../states/MenuState";
import ForgotPasswordForm from "../components/login/ForgotPasswordForm";

interface RouteContextProps extends PropsWithChildren {
    index: string,
    subIndex: string,
    title: string
}
const RouteContext = (props : RouteContextProps) => {
    const { index, subIndex, title, children } = props;

    const setMenuIndex = useSetRecoilState(MenuIndexState);

    useEffect(() => {
        setMenuIndex({ index, subIndex, title});
    },[index, subIndex, setMenuIndex])

    return (
        <>{children}</>
    )
}

const AppRouting = () => {

    const [isAuth, setIsAuth] = useRecoilState(AuthState);
    const setCsrfToken = useSetRecoilState(CsrfTokenState);
    const [ready, setReady] = useState(false);
    const [callIsAuth] = useIsAuth();
    const setCurrentUser = useSetRecoilState(CurrentUserState);
    const setAuthorizations = useSetRecoilState(AuthorizationsState);
    const navigate = useNavigate();
    const location = useLocation();
    const setLogoPath = useSetRecoilState(LogoPathState);
    const setCompanyName = useSetRecoilState(CompanyNameState);
    const [getCompanyInfo] = useGetCompanyInfo();

    const [navigateState, setNavigateState] = useRecoilState(NavigateState);

    useEffect(() => {
        if(!navigateState){
            return;
        }
        navigate(navigateState);
        setNavigateState("");
    },[navigateState, navigate, setNavigateState])

    useEffect(() => {
        getCompanyInfo().then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if(res.data.logo.value){
                    setLogoPath( `${process.env.REACT_APP_API}${res.data.logo.value}`);
                }
                setCompanyName(res.data.companyName.value)
            }
        })
    }, [])

    useEffect(() => {
        if (ready && !isAuth) {
            var newPasswordRegex = /^\/(new-password|activate)\/[a-z0-9-]+$/;
            if(!newPasswordRegex.test(location.pathname)){
                setNavigateState("/login");
            }
        }
    }, [ready, isAuth])

    useEffect(() => {
        callIsAuth()
            .then(res => {
                //Le token est tjr disponible et il est valide
                if (res.status === ReqStatus.SUCCESS && res.data !== undefined) {
                    setCsrfToken(res.data.token);
                    setAuthorizations(res.data.authorizations);
                    setCurrentUser(res.data.user);
                    setIsAuth(res.data.auth);
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                if (!ready) {
                    setTimeout(function () { setReady(true); }, 1000);
                }
            });
    }, [isAuth]);

    return (
        <>
            {!ready && <AppLoader />}
            {ready && <Outlet></Outlet>}
        </>
    );
};

const App = () => {
    const { routes } = useAppRoutes();   
    const router = useMemo(() => {
        return createBrowserRouter(
            createRoutesFromElements(
                <>
                    {/* 
                        la réinitialisation est en première position pour 
                        pas que AppRouting se lance et redirige vers le LoginForm 
                    */}
                    <Route path="/" element={<AppRouting />}>
                        <Route path="/" element={<Layout />}>
                            {routes.map((section) => (
                                section.children.map((route) => (
                                    route.enabled ?
                                    <Route path={route.path} element={ 
                                        <RouteContext index={section.index} subIndex={route.index} title={route.title}>
                                            {route.element()}
                                        </RouteContext>
                                    } /> :
                                    <Route path={route.path} element={<Box display="flex" justifyContent="center"><h1>403 - Accès interdit</h1></Box>} />
                                ))
                            ))}
                            <Route path="*" element={<Box display="flex" justifyContent="center"><h1>404 - Page non trouvée</h1></Box>} />
                        </Route>
                        <Route path={"/login"} element={<LoginForm />} />
                        <Route path={"/forgot-password"} element={<ForgotPasswordForm />} />
                        <Route path={"/new-password/:guid"} element={<NewPasswordForm route="new-password" />} />
                        <Route path={"/activate/:guid"} element={<NewPasswordForm route="activate" />} />
                    </Route>
                </>
            ),
            { basename: "/" }
        );
    }, [routes]);

    return (
        <Box display="flex" flexDirection={"column"} height={"100vh"}>
            <RouterProvider router={router} />
        </Box>
    )
}

export default App;
