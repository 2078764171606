import React, { useCallback, useState } from "react";
import { Box, Button, Typography, Paper, Divider, IconButton } from "@mui/material";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


interface AddEditFormCardProps {
  title: string;
  action?: React.ReactNode;
  children: React.ReactNode;
  withExpand?: boolean,
  isExpand?: boolean,
  maxWidth?: "sm" | "md" | "lg" | "xl" | "xxl" | string,
  
}

const AddEditFormCard = (props: AddEditFormCardProps) => {
  const { title, children, withExpand, isExpand, maxWidth, action } = props;
  const [isExpanded, setIsExpanded] = useState(isExpand ?? true);

  const handleToggleExpand = useCallback(() => {
    setIsExpanded((v) => !v);
  }, []);

  return (
    <Box maxWidth={(!maxWidth || maxWidth === "xxl") ? "100%" : maxWidth} mx={"auto"}>
      <Paper variant="outlined" sx={{ my: 2 }} >
        <Box display="flex" alignItems="center" sx={{ px: 2, height: "50px" }} justifyContent={"space-between"}>
          <Box flexGrow={1} onClick={withExpand ? handleToggleExpand : undefined} style={withExpand ? { cursor: "pointer" } : undefined}>
            <Typography variant="h5">
              {title}
            </Typography>
          </Box>
          <Box display="flex" alignItems={"center"}>
            {action}
            <Box ml={1}>
              {withExpand &&
                <IconButton color="info" onClick={handleToggleExpand}>
                  {isExpanded ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </IconButton>
              }
            </Box>
          </Box>
        </Box>
        <Divider />
        {withExpand ?
          <Collapse in={isExpanded}>
            <Box sx={{ p: 2 }}>{children}</Box>
          </Collapse>
          :
          <Box sx={{ p: 2 }}>{children}</Box>
        }
      </Paper>
    </Box>
  );
};

export default AddEditFormCard;
