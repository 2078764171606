import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import Image from "mui-image";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LogoPathState, MenuIndexState, MenuVisibleState } from "../states/MenuState";
import HeaderAvatar from "./HeaderAvatar";
import HeaderLoader from "./HeaderLoader";
import MenuIcon from '@mui/icons-material/Menu';
import { HeaderSearch } from "../custom/Routes";

const Header = () => {
    const menuIndex = useRecoilValue(MenuIndexState);
    const title = useMemo(() => {
        return menuIndex.title;
    }, [menuIndex]);

    const setMenuVisible = useSetRecoilState(MenuVisibleState);
    const logoPath = useRecoilValue(LogoPathState);

    return (
        <AppBar position="relative" elevation={0} >

            <Toolbar>

                <Box display="flex" justifyContent={"space-between"} width={"100%"}>
                    <Box display="flex" alignItems={"center"} >
                        <Box mr={2} alignItems={"center"}>
                            <IconButton sx={{ color: "white" }} onClick={() => setMenuVisible(v => !v)}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        {logoPath &&
                            <Box display="flex" alignItems={"center"} mr={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <Image fit="revert" height={50} width={"auto"} src={logoPath} errorIcon={null} />
                            </Box>
                        }
                        <Box>
                            <Typography
                                variant="h5"
                            >
                                <Box display="flex" alignItems={"center"}>{title ? `${title}` : ''}</Box>
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent={"flex-end"} alignItems={"center"} >
                        <HeaderSearch />
                        <HeaderAvatar />
                    </Box>
                </Box>


            </Toolbar>
            <HeaderLoader />
        </AppBar>
    )
}

export default Header;