import { Button, Grid, Paper, Typography } from "@mui/material";
import InputField from "../common/form/InputField";
import { FormattedMessage } from "react-intl";
import { FormProvider, useForm, useIsDirty } from "react-recoil-form";
import { GenericResponse, ReqStatus } from "../../stores/core/UseApi";
import { useSnackbar } from "notistack";
import { UserGroupModel } from "../../classes/user-groups/UserGroupModel";
import { useAddEditUserGroup, useGetUserGroups } from "../../stores/UserGroupsStore";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { useAddEditForm } from "../common/form/AddEditFormPanel";
import { useAddEditCore } from "../../stores/core/StoreCore";

interface GetUserGroupResponse{
    userGroup: UserGroupModel,
    redirectionsList: string[]
}
const UserGroupView = () => {

    let { id } = useParams();


    const { getData, addEditData } = useAddEditCore<UserGroupModel, GetUserGroupResponse>(`userGroups/add-edit`);

    const [redirections, setRedirections] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            resetInitialValues({ name: "" } as UserGroupModel);
            setRedirections([]);
            return;
        }
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                const _userGroup = res.data.userGroup;
                resetInitialValues(
                    { 
                        name: _userGroup.name, 
                        redirection: _userGroup.redirection 
                    } as UserGroupModel
                );
                setRedirections(res.data.redirectionsList);
            } else {
                navigate("/user-groups");
            }
        })
    }, [id]);

    const onSubmit = (request: UserGroupModel) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate("/user-groups");
            }
        });
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { name: "", redirection: "" } as UserGroupModel });

    return (

        <AddEditForm formId="userGroupsForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={`${!id ? "Ajouter " : "Modifier"} un rôle`} >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="name"
                            label="Rôle"
                            required
                            type="text"
                        />
                    </Grid>
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    );
}

export default UserGroupView;