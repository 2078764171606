import { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ListExportButtonsModelCore, ListModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import { GenericResponse, ReqStatus } from "../../../stores/core/UseApi";
import ListServer from "./ListServer";
import { Skeleton } from "@mui/material";
import ListItemActions from "./ListItemActions";
import ListSearchFilterDateRange from "./ListSearchFilterDateRange";
import { useRecoilState } from "recoil";
import { useLocation } from 'react-router-dom';
import { useListCoreFilters } from "../../../states/ListState";

interface ListCoreProps<TList extends ListModelCore, TData extends any> {
    getDatas: (paginationLimit: number, page: number, search?: ListSearchModelCore | undefined, csv?: boolean | undefined, pdf?: boolean | undefined, xls?: boolean | undefined, reinit?: boolean) => Promise<GenericResponse<TList>>
    defineDatasToSet: (data: TList) => TData[],
    getRowId: (row: TData) => string | number,
    columns: GridColDef[],
    actions?: {
        defaultRoute: string,
        add?: {
            title: string,
            route?: string,
        }
        edit?: {
            enable: boolean,
            conditions?: ((row: any) => boolean)[];
            route?: string,
            icon?: boolean,
        } | boolean,
        remove?: {
            enable: boolean,
            conditions?: ((row: any) => boolean)[];
            route?: string,
            icon?: boolean,
            getTextModal?: (row: TData) => string,
        } | boolean,
        others?: (row: TData) => JSX.Element,
        othersLeft?: (row: TData) => JSX.Element,
        minWidth?: number
    }
    selection?: {
        component: (selections: any[]) => JSX.Element
    }
    maxWidth?: "sm" | "md" | "lg" | "xl" | "xxl",
    rowHeight?: number,
    paginationOptionDefault?: number,
    paginationsOptions?: number[],
    filterDateRange?: {
        period: 'week' | 'month'
        fieldName: string
    }
}
const ListCore = <TList extends ListModelCore, TData>(props: ListCoreProps<TList, TData>) => {

    const { getDatas, getRowId, defineDatasToSet, columns, actions, maxWidth, selection, rowHeight, paginationOptionDefault, paginationsOptions, filterDateRange } = props;
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [datas, setDatas] = useState<any[]>([]);

    const location = useLocation();


    //location.pathname

    const listCoreFilter = useListCoreFilters(location.pathname);

    //const [search, setSearch] = useRecoilState(ListCoreFilterState);
    //const [search, setSearch] = useState<ListSearchModelCore | undefined>(undefined);

    const [exportButtons, setExportButtons] = useState<ListExportButtonsModelCore>();
    const navigate = useNavigate();
    const [forceRefresh, setForceRefresh] = useState(0);

    const refreshWithSearch = useCallback((search: ListSearchModelCore | undefined, paginationLimit: number, page: number, csv?: boolean, pdf?: boolean, xls?: boolean, reinit?: boolean) => {
        setLoading(true); // Set loading to true before fetching data
        getDatas(paginationLimit, page, search, csv, pdf, xls, reinit)
            .then((res) => {
                if (res.status === ReqStatus.SUCCESS && res.data) {
                    setDatas(defineDatasToSet(res.data));
                    setCount(res.data.count);
                    listCoreFilter.setSearch( () => res.data?.search ?? undefined );
                    listCoreFilter.setPage(page);
                    setExportButtons(res.data.exportButtons ?? undefined);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after the data has been fetched
            });
    }, [getDatas]);

    const refresh = useCallback((paginationLimit: number, page: number, csv?: boolean, pdf?: boolean, xls?: boolean, reinit?: boolean) => {
        refreshWithSearch(listCoreFilter.search, paginationLimit, page, csv, pdf, xls, reinit);
    }, [listCoreFilter.search, refreshWithSearch]);

    const _columns = useMemo(() => {
        let _cols = [...columns];

        if (actions) {
            _cols.push({
                field: 'actions',
                headerName: "Actions",
                sortable: false,
                minWidth: actions.minWidth ?? 250,
                flex: 1,
                renderCell: ({ row }) => {

                    return (<ListItemActions
                        defaultRoute={actions.defaultRoute}
                        edit={
                            actions.edit === true ? {
                                enable: true,
                            } :
                                (typeof actions.edit === 'object') ?
                                    {
                                        enable: actions.edit.conditions ?
                                            actions.edit.conditions.every(condition => condition(row)) :
                                            actions.edit.enable,
                                        route: actions.edit.route,
                                        icon: actions.edit.icon
                                    }
                                    :
                                    undefined
                        }
                        remove={
                            actions.remove === true ? {
                                enable: true,
                                callback: () => setForceRefresh(v => v + 1),
                            } :
                                (typeof actions.remove === 'object') ?
                                    {
                                        enable: actions.remove.conditions ?
                                            actions.remove.conditions.every(condition => condition(row)) :
                                            actions.remove.enable,
                                        callback: () => setForceRefresh(v => v + 1),
                                        route: actions.remove.route,
                                        getTextModal: actions.remove.getTextModal,
                                        icon: actions.remove.icon
                                    }
                                    :
                                    undefined
                        }
                        others={actions.others}
                        othersLeft={actions.othersLeft}
                        row={row}
                        id={getRowId(row)}
                    />
                    )
                }
            })
        }
        return _cols;
    }, [columns, actions, getRowId]);

    return (

        <Box display={"flex"} justifyContent="center" width={"100%"} height="100%" >
            {/* (maxWidth === undefined || maxWidth === "xxl") ? "100%" : maxWidth */}
            <Box display="flex" sx={{ overflowY: "auto" }} width={"100%"} flexDirection="column">
                {actions?.add && (
                    <Box display="flex" justifyContent="flex-end" mt={2} mr={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                navigate(`/${actions.add?.route || actions.defaultRoute}/add-edit`);
                            }}
                        >
                            {actions.add?.title}
                        </Button>
                    </Box>

                )}
                <ListServer<TData>
                    columns={_columns}
                    count={count}
                    datas={datas}
                    getDatas={refresh}
                    getDatasWithSearch={refreshWithSearch}
                    search={listCoreFilter.search}
                    setSearch={listCoreFilter.setSearch}
                    page={listCoreFilter.page}
                    setPage={listCoreFilter.setPage}
                    getRowId={getRowId}
                    exportButtons={exportButtons}
                    loading={loading}
                    forceRefresh={forceRefresh}
                    selection={selection}
                    rowHeight={rowHeight}
                    paginationOptionDefault={paginationOptionDefault}
                    paginationsOptions={paginationsOptions}
                    filterDateRange={filterDateRange}
                />
            </Box>
        </Box>
    );

}

export default ListCore;
