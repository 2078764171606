import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Box, Container, Grid, Paper } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { createOptionsForAutocomplete, createOptionsForSelect } from "../../../utils/DataUtils";
import NumberField from "../../../components/common/form/NumberField";
import DateField, { getDatetimeNow } from "../../../components/common/form/DateField";
import dayjs from "dayjs";
import { BetStatusCancel, BetStatusInProgress, BetStatusLose, BetStatusOptions, BetStatusWin, PlayerBetsRoute } from "../../classes/PlayerBetsModel";
import AddEditFormList from "../../../components/common/form/AddEditFormList";
import { useField, useFieldArray, useForm, useFormValues } from "react-recoil-form";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { CurrentUserState } from "../../../states/AuthState";
import { useRecoilValue } from "recoil";

const PlayerBetMargin = () => {

    const formValues = useFormValues();

    const marginWin = useMemo(() => {

        let playerGain = 0;
        if (formValues.amount && formValues.cote) {
            playerGain = (formValues.amount * formValues.cote) - formValues.amount;
        }

        let bookGain = 0;
        formValues.book_bets?.forEach((bet: any) => {
            bookGain += (bet.amount * bet.cote) - bet.amount;
        });
        formValues.newBookBets?.forEach((bet: any) => {
            bookGain += (bet.amount * bet.cote) - bet.amount;
        });


        return Math.round( bookGain - playerGain );

    }, [formValues])


    const marginLose = useMemo(() => {
        let bookMise = 0;
        formValues.book_bets?.forEach((bet: any) => {
            bookMise += bet.amount;
        });
        formValues.newBookBets?.forEach((bet: any) => {
            bookMise += bet.amount;
        });

        return formValues.amount - bookMise;

    }, [formValues])

    return (
        <Box component={Paper} mx={-1} p={1} >

            <Box>Marge si joueur gagne : {marginWin} €</Box>
            <Box>Marge si joueur perd : {marginLose} €</Box>
        </Box>
    )
}

const PlayerBetView = () => {
    const { getData, addEditData } = useAddEditCore<any, { playerBet: any, players: any, books: any, users: any }>(`${PlayerBetsRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const currentUser = useRecoilValue(CurrentUserState);

    const refresh = useCallback(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {

                setPlayers(res.data.players.map((p: any) => { return {
                    id: p.id,
                    label: `${p.name} (${p.agent.name})`
                }}));

                setBooks(res.data.books.map((p: any) => { return {
                    value: p.id,
                    label: `${p.name} (${p.agent.name})`
                }}));

                setUsers(createOptionsForSelect(res.data.users));

                if (id) {
                    const _data = res.data.playerBet;
                    resetInitialValues(
                        {
                            user_id: _data.user_id,
                            player_id: _data.player_id,
                            name: _data.name,
                            cote: _data.cote,
                            amount: _data.amount,
                            status: _data.status,
                            date: _data.date,
                            book_bets: (Array.isArray(_data.book_bets) && _data.book_bets.length > 0) ? _data.book_bets.map((b: any) => {
                                return {
                                    id: b.id,
                                    player_bet_id: b.player_bet_id,
                                    book_id: b.book_id,
                                    cote: b.cote,
                                    amount: b.amount,
                                    status: b.status
                                };
                            }) : undefined,
                            addElements: "1"
                        }
                    );
                } else {
                    resetInitialValues({
                        addElements: "1",
                        date: getDatetimeNow(),
                        status: 1,
                        user_id: currentUser?.id ?? 0
                    });
                }
            } else {
                navigate(`/${PlayerBetsRoute}/`);
            }
        })
    }, [id]);

    const onSubmit = (request: any) => {

        const _request = JSON.parse(JSON.stringify(request));

        if (!_request.book_bets || _request.book_bets.length === 0) {
            delete _request.book_bets;
        }
        if (!_request.newBookBets || _request.newBookBets.length === 0) {
            delete _request.newBookBets;
        }

        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    refresh();
                } else if (Array.isArray(res.data) && res.data.length == 1) {
                    navigate(`/${PlayerBetsRoute}/add-edit/${(res.data)[0].id}`);
                } else {
                    navigate(-1);
                }
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit });

    const [books, setBooks] = useState<SelectOptionsProps[]>([]);
    const [players, setPlayers] = useState<AutocompleteOption[]>([]);
    const [users, setUsers] = useState<SelectOptionsProps[]>([]);

    useEffect(() => {
        refresh();
    }, [id]);

    const { fieldValue: status } = useField({ name: 'status' });
    const fieldArrays = ["id", "player_bet_id", "user_id", "cote", "amount", "status", "book_id"];
    const bookBetsFieldArray = useFieldArray({ name: "book_bets", fieldNames: fieldArrays });
    const newBookBetsFieldArray = useFieldArray({ name: "newBookBets", fieldNames: fieldArrays });

    const getDefaultStatusForBetBook = useCallback((status: number) => {
        if (status == BetStatusInProgress) {
            return BetStatusInProgress;
        }
        if (status == BetStatusCancel) {
            return BetStatusCancel;
        }
        if (status == BetStatusLose) {
            return BetStatusWin;
        }
        return BetStatusLose;
    }, []);

    const updateStatusOfBookBet = useCallback((status: number) => {
        if (isNaN(status)) { return; }

        let _array = bookBetsFieldArray.getFieldArrayValue();
        _array.forEach((v) => { v.status = getDefaultStatusForBetBook(status); })
        bookBetsFieldArray.setFieldArrayValue(_array);

        _array = newBookBetsFieldArray.getFieldArrayValue();
        _array.forEach((v) => { v.status = getDefaultStatusForBetBook(status); })
        newBookBetsFieldArray.setFieldArrayValue(_array);
    }, [bookBetsFieldArray, newBookBetsFieldArray])


    return (
        <AddEditForm 
            formId="betForm" 
            formHandleCancel={resetInitialValues} 
            formHandleSubmit={handleSubmit} 
            isDirty={isDirty} 
            actions="top" 
            returnAction={{
                event: () => navigate(`/${PlayerBetsRoute}/`)
            }}
        >
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un pari"} maxWidth="md" withExpand={true} isExpand={true}>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                        <InputField size="small" name="name" label="Libellé" type="text" required />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <DateField size="small" name="date" label="Date" type="datetime" required />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <AutocompleteField
                            name="player_id"
                            label="Joueur"
                            required
                            options={players}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <SelectField
                            size="small"
                            name="status"
                            label="Statut"
                            options={BetStatusOptions}
                            required
                            handleChange={(v) => updateStatusOfBookBet(v)}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <NumberField size="small" name="amount" label="Montant" required />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <NumberField size="small" name="cote" label="Côte" required maxDecimals={4} />
                    </Grid>

                </Grid>
            </AddEditFormCard>

            <AddEditFormCard title={"Paris books"} maxWidth="md" withExpand={true} isExpand={true}>
                <AddEditFormList
                    fields={[{
                        name: "book_id",
                        type: "select",
                        selectOptions: books,
                        label: "Book",
                        xs: 6,
                        required: true
                    },
                    {
                        name: "status",
                        type: "select",
                        label: "Statut",
                        selectOptions: BetStatusOptions,
                        required: true,
                        xs: 6,
                    },
                    {
                        name: "amount",
                        type: "number",
                        label: "Montant",
                        required: true,
                        xs: 6,
                    },
                    {
                        name: "cote",
                        type: "number",
                        label: "Cote",
                        xs: 4,
                        required: true,
                        maxDecimals: 4
                    },
                    ]}
                    hiddenFields={{
                        add: ["user_id"],
                        edit: ["id", "player_bet_id", "user_id"]
                    }}
                    entity={"book_bets"}
                    title={"Paris books"}
                    buttonAdd={{ title: "Ajouter un pari book" }}
                    defaultItem={{ status: getDefaultStatusForBetBook(status), user_id: currentUser?.id ?? 0 }}
                />
            </AddEditFormCard>

            <Container maxWidth="md">
                <PlayerBetMargin />
            </Container>
        </AddEditForm>


    )
}

export default PlayerBetView;