import { GridColDef } from "@mui/x-data-grid";
import { PlayerListModel } from "../../classes/PlayerModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { Badge, Box, Chip } from "@mui/material";
import { useMemo } from "react";
import { BetStatusInProgress } from "../../classes/PlayerBetsModel";
import { CalculSolde } from "../../utils/MargeUtils";
import ColumnSolde from "../common/ColumnSolde";


const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: "Joueur",
        editable: false,
        filterable: false,
        width: 120,
        valueGetter: ({ row }) => row.name
    },
    {
        field: 'agent.name',
        headerName: "Agent",
        editable: false,
        filterable: false,
        width: 120,
        valueGetter: ({ row }) => row.agent?.name
    }, 
    {
        field: 'solde',
        headerName: "Solde",
        editable: false,
        filterable: false,
        width: 180,
        renderCell: ({ row }) => <ColumnSolde bets={row.player_bets} payments={row.player_payments} isBook={false} />
    }
];


const PlayerList = () => {

    const routeController = "project-bets/bets/players";
    const [get] = useGetListCore<PlayerListModel>(`/${routeController}/index`);

    return (
        <ListCore<PlayerListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.players}
            getRowId={(row) => row.id}
            rowHeight={70}
            columns={columns}
            paginationOptionDefault={100}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un joueur"
                },
                edit: {
                    enable: true,
                    icon: true
                },
                remove: false,
                minWidth: 50
            }}
        />
    );
}

export default PlayerList;