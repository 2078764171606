import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './layout/App';
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider } from 'react-recoil-form';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs  } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

import dayjsUtc from 'dayjs/plugin/utc'; // Importez le plugin UTC
import dayjsTimezone from 'dayjs/plugin/timezone';

import Messages from './messages/FR';
import MessagesCustom from './custom/messages/FR';

const theme = createTheme();

//toutes les notif ont desormais une icone pour fermer
const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: any) => {
  notistackRef?.current?.closeSnackbar(key);
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const locale = 'fr';
document.documentElement.lang = locale;
dayjs.locale(locale);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.tz.setDefault('Europe/Paris');

//on surcharge les messages par celui du plugin
const messages = {
  ...Messages,
  ...MessagesCustom, 
};

/*
if(locale !== "fr"){
  let property: keyof typeof messages;
  for (property in messages) {
    messages[property] = `[${locale.toUpperCase()}] ${messages[property]}`;
  }
}*/

//modification du 
document.title = process.env.REACT_APP_TITLE ?? "React Application"; 
const metaDescription = document.querySelector('meta[name="description"]');
if (metaDescription) {
  metaDescription.setAttribute('content', process.env.REACT_APP_TITLE ?? "React Application" );
} 

root.render(
  <RecoilRoot>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <FormProvider>
    
          <SnackbarProvider maxSnack={8} ref={notistackRef} action={(key) =>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => {
              onClickDismiss(key);
            }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }>
            <ThemeProvider theme={theme}>
              <IntlProvider locale={locale} messages={messages} >
                <CssBaseline />
                <App />
              </IntlProvider>
            </ThemeProvider>
          </SnackbarProvider>
      </FormProvider>
    </LocalizationProvider>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
