import { Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Paper, TextField } from "@mui/material";
import { ListExportButtonsModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import { useCallback, useMemo } from "react";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface ListSearchServerProps {
    search: ListSearchModelCore,
    setSearch: (callback: (value: ListSearchModelCore | undefined) => ListSearchModelCore | undefined) => void
    exportButtons?: ListExportButtonsModelCore,
    refresh: (csv?: boolean, pdf?: boolean, xls?: boolean, reinit?: boolean) => void,
    refreshWithSearch: (search: any, csv?: boolean, pdf?: boolean, xls?: boolean, reinit?: boolean) => void,
    datasCount?: number,
}

const ListSearchServer = (props: ListSearchServerProps) => {

    const { search, setSearch, exportButtons, refresh, refreshWithSearch, datasCount } = props;

    const reinitFilters = useCallback(() => {
        setSearch((v) => {
            if (!v) { return; }
            let _clone = { ...v };
            Object.entries(_clone).forEach(([key, item]) => {
                if (item.html.field === "text") {
                    item.value = "";
                } else if (item.html.field === "select") {
                    item.value = null;
                }
                else if (item.html.field === "checkbox") {
                    item.value = 1;
                }
                else if (item.html.field === "date") {
                    item.value = null;
                }
            });

            refreshWithSearch(_clone, false, false, false, true);
            return _clone;
        });

    }, [refreshWithSearch, setSearch]);

    const searchArray = useMemo(() => { return Object.entries(search); }, [search]);

    return (
        <>
            {
                (searchArray.length > 0 || exportButtons?.csv || exportButtons?.pdf || exportButtons?.xls) &&
                <Paper variant="outlined" sx={{ width: "100%" }}>
                    <Box m={2}>
                        {searchArray.length > 0 &&
                            <Grid container spacing={1} >
                                {searchArray.map(([searchKey, searchItem]) => (
                                    <Grid key={searchKey} item xl={3} lg={3} md={6} sm={6} xs={6}>
                                        <Box mx={0}>
                                            {
                                                searchItem.html.field === "text" &&
                                                <TextField
                                                    fullWidth
                                                    size={"small"}
                                                    type={"text"}
                                                    value={searchItem.value ?? ""}
                                                    onChange={(e) => {
                                                        setSearch((v) => {
                                                            if (!v) { return; }
                                                            let _clone = { ...v };
                                                            _clone[searchKey].value = e.target.value;
                                                            return _clone;
                                                        })
                                                    }}
                                                    label={searchItem.html.attrs?.placeholder}
                                                    variant={"outlined"}
                                                />
                                            }

                                            {
                                                searchItem.html.field === "date" &&
                                                <DatePicker
                                                    sx={{ width: "100%" }}
                                                    
                                                    label={searchItem.html.label}
                                                    value={searchItem.value ? dayjs(searchItem.value).tz() : null}
                                                    onChange={(v) => {
                                                        setSearch((s) => {
                                                            if (!s) { return; }
                                                            let _clone = { ...s };
                                                            _clone[searchKey].value = v ? v.format("YYYY-MM-DD") : null;
                                                            return _clone;
                                                        })
                                                    }}
                                                />
                                            }

                                            {
                                                searchItem.html.field === "datetime" &&
                                                <DateTimePicker
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": {
                                                            padding: "8.5px 14px 8.5px 14px",
                                                        }
                                                    }}
                                                    
                                                    label={searchItem.html.label}
                                                    value={searchItem.value ? dayjs(searchItem.value).tz() : null}

                                                    onChange={(v) => {
                                                        setSearch((s) => {
                                                            if (!s) { return; }
                                                            let _clone = { ...s };
                                                            _clone[searchKey].value = v ? v.format("YYYY-MM-DDTHH:mm") : null;
                                                            return _clone;
                                                        })
                                                    }}
                                                />
                                            }

                                            {
                                                searchItem.html.field === "select" &&
                                                <TextField
                                                    fullWidth
                                                    select
                                                    size={"small"}
                                                    label={searchItem.html.attrs?.placeholder}
                                                    value={(searchItem.value === null || searchItem.value === undefined || searchItem.value === "") ? -1 : searchItem.value}
                                                    onChange={(e) => {
                                                        setSearch((v) => {
                                                            if (!v) { return; }
                                                            let _clone = { ...v };
                                                            const _value = Number.isNaN(Number(e.target.value)) ? e.target.value : +e.target.value;
                                                            _clone[searchKey].value = _value;
                                                            return _clone;
                                                        })
                                                    }}
                                                >
                                                    {searchItem.html.empty && <MenuItem value={-1}> {searchItem.html.empty} </MenuItem>}
                                                    {searchItem.datasource && Object.entries(searchItem.datasource).map(([datasourceKey, datasourceItem]) => (

                                                        <MenuItem key={datasourceKey} value={datasourceKey}> {datasourceItem} </MenuItem>
                                                    ))}
                                                </TextField >
                                            }

                                            {
                                                searchItem.html.field === "checkbox" &&
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={Boolean(searchItem.value)}
                                                        onChange={(e) => {
                                                            setSearch((v) => {
                                                                if (!v) { return; }
                                                                let _clone = { ...v };
                                                                _clone[searchKey].value = e.target.checked;
                                                                return _clone;
                                                            })
                                                        }}
                                                    />
                                                } label={searchItem.html.attrs?.placeholder} />
                                            }
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                        <Box my={2} display="flex" justifyContent="center">
                            {searchArray.length > 0 &&
                                <>
                                    <Box > <Button variant="contained" color="primary" onClick={() => refresh()}> Filtrer </Button> </Box>
                                    <Box ml={2}> <Button variant="contained" color="warning" onClick={() => reinitFilters()}> Réinitialiser </Button> </Box>
                                </>
                            }

                            {exportButtons?.csv &&
                                <Box ml={2}>
                                    <Button variant="contained" color="success" onClick={() => refresh(true, false, false)} disabled={!datasCount || datasCount === 0}>
                                        Export CSV
                                    </Button>
                                </Box>
                            }
                            {exportButtons?.pdf &&
                                <Box ml={2}>
                                    <Button variant="contained" color="success" onClick={() => refresh(false, true, false)} disabled={!datasCount || datasCount === 0}>
                                        Export PDF
                                    </Button>
                                </Box>
                            }
                            {exportButtons?.xls &&
                                <Box ml={2}>
                                    <Button variant="contained" color="success" onClick={() => refresh(false, false, true)} disabled={!datasCount || datasCount === 0}>
                                        Export Excel
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Paper>
            }
        </>
    );
}

export default ListSearchServer;