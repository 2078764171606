import { SectionItem } from "../layout/AppRoutes";
import { UserSectionRoutes } from "../layout/routes/UserSectionRoutes";
import BookList from "./components/books/BookList";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlayerList from "./components/players/PlayerList";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookView from "./components/books/BookView";
import PlayerView from "./components/players/PlayerView";
import PlayerBetView from "./components/player-bets/PlayerBetView";
import PlayerBetList, { PlayerBetListInProgress } from "./components/player-bets/PlayerBetList";
import EuroIcon from '@mui/icons-material/Euro';
import { PlayerBetsRoute } from "./classes/PlayerBetsModel";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MarginList from "./components/margins/MarginList";
import { MarginsRoute } from "./classes/MarginModel";
import EqualizerIcon from '@mui/icons-material/Equalizer';

export const HeaderSearch = () => {
    return (
        <>
        </>
    )
}

export const Routes: SectionItem[] = [
	UserSectionRoutes,
	{
        title: "Bets",
        index: "BETS",
        children: [
            {
                index: "BOOK_LIST",
                path: "/project-bets/bets/books/",
                title: "Books",
                element: () => <BookList />,
                menuVisible: true,
                menuIcon: <MenuBookIcon />,
                authorization: {
                    controller: "Books",
                    action: "index"
                }
            },
            {
                index: "BOOK_VIEW",
                path: "/project-bets/bets/books/add-edit/:id?",
                title: "Books",
                element: () => <BookView />,
                menuVisible: false,
                authorization: {
                    controller: "Books",
                    action: "addEdit"
                }
            },
            {
                index: "PLAYER_LIST",
                path: "/project-bets/bets/players/",
                title: "Joueurs",
                element: () => <PlayerList />,
                menuVisible: true,
                menuIcon: <PeopleAltIcon />,
                authorization: {
                    controller: "Players",
                    action: "index"
                }
            },
            
            {
                index: "PLAYER_VIEW",
                path: "/project-bets/bets/players/add-edit/:id?",
                title: "Joueurs",
                element: () => <PlayerView />,
                menuVisible: false,
                authorization: {
                    controller: "Players",
                    action: "addEdit"
                }
            },
            {
                index: "BET_LIST_HOME",
                path:  `/`,
                title: "Paris",
                element: () => <PlayerBetList />,
                menuVisible: false,
                menuIcon: <EuroIcon />,
                authorization: {
                    controller: "PlayerBets",
                    action: "index"
                }
            },
            {
                index: "BET_LIST_FINISH",
                path:  `/${PlayerBetsRoute}/`,
                title: "Paris",
                element: () => <PlayerBetList />,
                menuVisible: true,
                menuIcon: <AssignmentTurnedInIcon />,
                authorization: {
                    controller: "PlayerBets",
                    action: "index"
                }
            },
            {
                index: "BET_VIEW",
                path: `/${PlayerBetsRoute}/add-edit/:id?`,
                title: "Paris",
                element: () => <PlayerBetView />,
                menuVisible: false,
                authorization: {
                    controller: "PlayerBets",
                    action: "addEdit"
                }
            },
            
            {
                index: "MARGIN_LIST",
                path:  `/${MarginsRoute}/`,
                title: "Marges",
                element: () => <MarginList />,
                menuVisible: true,
                menuIcon: <EqualizerIcon />,
                authorization: {
                    controller: "Margins",
                    action: "index"
                }
            },
        ]
    }
];