import { Chip } from "@mui/material";
import { useMemo } from "react";

const FormattedAmount = (props: { prefix?: string, amount: number, symbol?: string, gain?: boolean, chip?: boolean }) => {

	const { amount, symbol, gain, chip } = props;

	const formattedAmount = useMemo(() => {
		return `${amount.toLocaleString('fr-FR')}${symbol ? ` ${symbol}` : " €"}`;
	}, [amount])

	const color = useMemo(() => {
		if (amount > 0) { return gain ? "success" : "primary"; };

		if (amount == 0) { return gain ? "default" : "primary"; }

		return "error";
	}, [amount]);

	return (
		<>
			{
				chip ?
					<Chip sx={{ fontSize: "12px", height: "20px" }} label={formattedAmount} color={color}></Chip>
					:
					<>{formattedAmount }</>
			}
		</>

	)
}

export default FormattedAmount;