import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { CalculSolde } from "../../utils/MargeUtils";
import { Alert, Box, Button, Card, Container, Divider, Grid, Paper, Tab, Tabs } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import FormattedAmount from "../common/FormattedAmount";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import ListSearchServer from "../../../components/common/list/ListSearchServer";
import { ListModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import { BetStatusInProgress } from "../../classes/PlayerBetsModel";
import { TabContext, TabList } from "@mui/lab";
import { datetimeFormat } from "../../../utils/DateUtils";
import BetStatusChip from "../common/BetStatusChip";

interface MarginListSectionMatchProps {
	playerBets: any[],
	players: any[],
	books: any[],
	agents: any[]
}
const MarginListSectionMatch = (props: MarginListSectionMatchProps) => {

	const { playerBets, players, books, agents } = props;

	const getName = useCallback((id: number, array: any[]) => {
		return array.find((x) => x.id == id)?.name;
	}, []);

	const betIsInternal = useCallback((b: any) => {
		const _player = players.find((x) => x.id == b.player_id);
		if (_player) {
			return _player.internal;
		} else {
			return false;
		}
	}, [players]);

	return (
		<Grid container spacing={1}>

			{playerBets?.map((b) => (
				<Grid item xs={12} key={`playerBet_${b.id}`}>
					<Card>
						<Box px={1} py={0.5}>
							<Box display="flex">
								<Box><strong>{getName(b.player_id, players)}</strong> - {b.name ?? ""}</Box>
							</Box>
							<Divider />
							<Grid container py={0.5} >

								<Grid item xs={6} >
									<FormattedAmount amount={b.amount} /> * {parseFloat(b.cote).toString()}
								</Grid>
								<Grid item xs={6} display="flex" justifyContent={"space-between"}>
									<BetStatusChip status={b.status} />
									<Box ml={2}>
										<FormattedAmount amount={b.gain} gain chip />
									</Box>
								</Grid>
							</Grid>
							{
								b.book_bets && b.book_bets.length > 0 &&
								<>
									<Divider />
									<Grid container spacing={1} py={1}>
										{b.book_bets?.map((bb: any, key: number) => {
											return (
												<Grid item xs={12} display="flex" justifyContent={"space-between"}>

													<Grid container>
														<Grid item xs={3}>
															<strong>{getName(bb.book_id, books)}</strong>
														</Grid>
														<Grid item xs={3} textAlign={"center"}>
															<BetStatusChip status={bb.status} />
														</Grid>
														<Grid item xs={3} textAlign={"center"}>
															{bb.cote} * <FormattedAmount amount={bb.amount} />
														</Grid>
														<Grid item xs={3} textAlign={"end"}>
															<FormattedAmount amount={bb.gain} gain chip />
														</Grid>
													</Grid>
												</Grid>
											)
										}
										)}
									</Grid>
								</>
							}
							<Divider />
							<Box display="flex" justifyContent={"space-between"} mt={0.5}>
								<Box>{datetimeFormat(b.date)}</Box>
								<Box><FormattedAmount amount={b.com} chip /></Box>
							</Box>
						</Box>
					</Card>
				</Grid>
			))}

		</Grid>
	)
}

export default MarginListSectionMatch;