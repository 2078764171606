import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosRequestHeaders } from "axios";
import { VariantType, useSnackbar } from 'notistack';
import { useCallback, useState } from "react";
import { trackPromise } from 'react-promise-tracker';
import instanceAxiosDefault from './AxiosCore';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AuthState, CsrfTokenState } from "../../states/AuthState";

export interface ApiParams {
    //errorMode?: ErrorMode,
    /**
     * (Deprecated) Ne plus utiliser cet objet
     */
    canBeAborted?: boolean
}

interface Params extends ApiParams {
    route: string,
    withParseResult?: boolean,
    instanceAxios?: AxiosInstance,
    instanceAxiosMethod?: 'POST' | 'GET' | 'PUT' | 'DELETE',
    trackPromiseEnabled?: boolean,
    contentType?: "json" | "form"
}

interface AxiosGenericResponse<TResponseType> {
    datas: TResponseType,
    error: string[],
    info: string[],
    success: string[]
}

export interface GenericResponse<TResponseType> {
    data: TResponseType | undefined,
    status: ReqStatus
}

/*
export enum ErrorMode {
    EXCEPTION,
    SNACKBAR,
    NOTHING
}*/

export enum ReqStatus {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    TECHNICAL = 'TECHNICAL',
    ABORT = "ABORT"
}

export const objectToFormData = (obj: any, formData = new FormData(), parentKey = '') => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const finalKey = parentKey ? `${parentKey}[${key}]` : key;

            if (value instanceof File) {
                formData.append(finalKey, value);
            } 
            else {
                if (Array.isArray(value)) {
                    if(value.length > 0){
                        let value_id = 0;
                        value.forEach((item: any) => {
                            if(item === null || item === undefined){
                                formData.append(`${finalKey}[]`, "");
                            }else if(typeof(item) === "string" || typeof(item) === "number"){
                                formData.append(`${finalKey}[]`, item.toString());
                            }else{
                                objectToFormData(item, formData, `${finalKey}[${value_id}]`);
                                value_id++;
                            }
                        });
                    }else{
                        formData.append(`${finalKey}`, "");
                    }
                } else if (typeof value === 'object' && value !== null) {
                    objectToFormData(value, formData, finalKey);
                } else {
                    formData.append(finalKey, (value === null || value === undefined) ? "" : value);
                }
            }
        }
    }
    return formData;
};



export function useApi<TResponseType, TRequestParams>(params: Params): [
    (data?: TRequestParams, urlExtension?: string) => Promise<GenericResponse<TResponseType>>,
    ReqStatus] {
    const { route, withParseResult, instanceAxios, instanceAxiosMethod, trackPromiseEnabled, contentType } = params;

    const [status, setstatus] = useState<ReqStatus>(ReqStatus.IDLE);
    const { enqueueSnackbar } = useSnackbar();
    const isInstanceAxiosDefault = instanceAxios === undefined;
    const instance = isInstanceAxiosDefault ? instanceAxiosDefault : instanceAxios;
    const method = instanceAxiosMethod === undefined ? 'POST' : instanceAxiosMethod;
    const _trackPromiseEnabled = trackPromiseEnabled !== undefined ? trackPromiseEnabled : true;
    const csrfToken = useRecoilValue(CsrfTokenState);
    const _contentType = contentType === undefined ? "json" : contentType;
    const _withParseResult = (withParseResult === undefined || withParseResult === true);
    const setIsAuth = useSetRecoilState(AuthState);

    const sendNotifs = useCallback((messages: string[], variant: VariantType) => {
        if (Array.isArray(messages) && messages.length > 0) {
            messages.forEach((msg: string) => {
                enqueueSnackbar(msg, { variant: variant });
            })
        }
    }, [enqueueSnackbar]);

    const parseResponse = useCallback((response: AxiosGenericResponse<TResponseType>) => {

        sendNotifs(response.info, 'info');
        sendNotifs(response.success, 'success');
        sendNotifs(response.error, 'error');

        let reqStatus: ReqStatus = ReqStatus.SUCCESS;
        if (Array.isArray(response.error) && response.error.length > 0) {
            reqStatus = ReqStatus.ERROR;
        }
        setstatus(reqStatus);

        return {
            data: response.datas as TResponseType,
            status: reqStatus
        } as GenericResponse<TResponseType>;
    }, [sendNotifs]);

    const doApiCall = useCallback((data?: TRequestParams, urlExtension?: string) => {

        const call = async (dataCalled?: TRequestParams, urlExtensionCalled?: string): Promise<GenericResponse<TResponseType>> => {
            let response: AxiosResponse<any> | undefined;
            try {
                if (!instance) {
                    throw new Error("instance http non défini");
                }

                setstatus(ReqStatus.LOADING);
                const url = route + (urlExtensionCalled !== undefined ? urlExtensionCalled : "");

                const headers: AxiosRequestHeaders = {};
                headers['Content-Type'] = _contentType === "form" ? 'application/x-www-form-urlencoded' : 'application/json';
                headers['X-Application'] = "react_front";
                if (csrfToken) {
                    instance.defaults.headers.common['X-CSRF-Token'] = csrfToken;
                }

                let promise: AxiosPromise<any>;
                if (method === "GET") {
                    promise = instance(
                        {
                            method: method,
                            params: dataCalled,
                            url,
                            headers
                        }
                    );
                } else {
                    promise = instance({
                        method: method,
                        data: objectToFormData(dataCalled),
                        url,
                        headers
                    });
                }
                if (_trackPromiseEnabled) {
                    trackPromise(promise);
                }
                response = await promise;

                if (response && response.status === 200) {
                    if (isInstanceAxiosDefault) {
                        const responseData = ((typeof response.data === "string" && _withParseResult) ? JSON.parse(response.data) : response.data) as AxiosGenericResponse<TResponseType>;
                        return parseResponse(responseData);
                    } else {
                        return {
                            data: response.data as TResponseType,
                            status: ReqStatus.SUCCESS
                        } as GenericResponse<TResponseType>;
                    }
                } else {
                    throw new Error("Une erreur est survenue - http code " + response.status);
                }
            } catch (error: any) {
                console.log(error);
                let errorMsg = "";
                const status = ReqStatus.TECHNICAL;
                if(error && error.response && error.response.status){
                    if(error.response.status === 401){
                        setIsAuth(false);
                        errorMsg = "Vous êtes déconnecté";
                    }
                    else if(error.response.status === 403){
                        errorMsg = "Vous n'avez pas les droits pour cette fonctionnalité";
                    }else{
                        errorMsg = "Une erreur est survenue : "+error.response.data;
                    }
                }else{
                    errorMsg = error.toString();
                }

                setstatus(ReqStatus.ERROR);
                enqueueSnackbar(errorMsg, { variant: 'error' });
                const result: GenericResponse<TResponseType> = {
                    data: undefined,
                    status: status
                };
                return result;
            }
        };

        return call(data, urlExtension);
    }, [method, route, instance, _trackPromiseEnabled, isInstanceAxiosDefault, enqueueSnackbar, csrfToken, _contentType, _withParseResult, parseResponse]);

    return [doApiCall, status];
}