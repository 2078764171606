import { useCallback, useMemo, useState } from "react";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard"
import { Box, Divider, Grid, IconButton } from "@mui/material";
import BetStatusChip from "./BetStatusChip";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { datetimeFormat } from "../../../utils/DateUtils";
import dayjs from "dayjs";
import { CalculCommission, CalculGain } from "../../utils/MargeUtils";
import { DateTimePicker } from "@mui/x-date-pickers";
import FormattedAmount from "./FormattedAmount";
import { FormattedMessage } from "react-intl";
import { SelectOptionsProps } from "../../../components/common/form/SelectField";

const BetsDetails = (props: { bets: any[], players: SelectOptionsProps[], books: SelectOptionsProps[], isBook: boolean }) => {
	const { bets, players, books, isBook } = props;

	const [start, setStart] = useState<dayjs.Dayjs | null>(null);
	const [end, setEnd] = useState<dayjs.Dayjs | null>(null);

	const betsFiltered = useMemo(() => {

		let _bets: any[] = JSON.parse(JSON.stringify(bets));

		_bets.forEach((bet) => {
			bet.gain = CalculGain(bet, isBook);
			if (!isBook) {
				bet.commission = CalculCommission(bet);
			}
		});

		if (start || end) {
			_bets = _bets.filter((b) => {
				const date = dayjs(isBook ? b.player_bet.date : b.date).tz();
				if (start && date.isBefore(start)) {
					return false;
				}
				if (end && date.isAfter(end)) {
					return false;
				}
				return true;
			})
		}

		return _bets.sort((a, b) => {

			const date_a = isBook ? a.player_bet.date : a.date;
			const date_b = isBook ? b.player_bet.date : b.date;

			const dateA = dayjs(date_a).tz();
			const dateB = dayjs(date_b).tz();

			// Compare les dates
			if (dateA.isBefore(dateB)) {
				return 1;
			}
			if (dateA.isAfter(dateB)) {
				return -1;
			}
			return 0;
		});
	}, [bets, start, end]);

	const totalCom = useMemo(() => {
		if (isBook) { return 0; }
		let total = 0;
		betsFiltered.forEach((b) => {
			total += b.commission;
		})
		return total;
	}, [betsFiltered]);

	const totalGain = useMemo(() => {
		let total = 0;
		betsFiltered.forEach((b) => {
			total += b.gain;
		})
		return total;
	}, [betsFiltered]);

	const navigate = useNavigate();

	const navigateToBet = useCallback((bet: any) => {
		const playerBetId = isBook ? bet.player_bet.id : bet.id;
		navigate('/project-bets/bets/player-bets/add-edit/' + playerBetId);
	}, [])

	const getNameOfBook = useCallback((id: number) => {
		return books.find((x) => x.value == id)?.label;
	},[books]);


	const getNameOfPlayer = useCallback((id: number) => {
		return players.find((x) => x.value == id)?.label;
	},[players]);

	return (
		<AddEditFormCard title={`Paris (${betsFiltered.length})`} maxWidth="md" withExpand={true} isExpand={false}>

			{
				betsFiltered?.length > 0 &&
				<>
					<Box display={"flex"} mb={1}>
						<DateTimePicker
							sx={{ "& .MuiInputBase-input": { padding: "8.5px 14px 8.5px 14px" } }}
							label={"Du"}
							value={start}
							onChange={(v) => setStart(v ? v : null)}
						/>
						<Box ml={2}>
							<DateTimePicker
								sx={{ "& .MuiInputBase-input": { padding: "8.5px 14px 8.5px 14px" } }}
								label={"Au"}
								value={end}
								onChange={(v) => setEnd(v ? v : null)}
							/>
						</Box>
					</Box>
					<Box mb={1} display="flex" justifyContent={"space-between"}>
						<Box>Gain : <FormattedAmount amount={totalGain} chip gain /></Box>
						<Box>{!isBook && <>Commission : <FormattedAmount amount={totalCom} chip /></>}</Box>
					</Box>


					<Grid container display="flex" alignItems={"center"}>
						<Grid item xs={4}>Paris</Grid>
						<Grid item xs={4} display="flex" flexDirection="column" alignItems="center">Mise</Grid>
						<Grid item xs={3} display="flex" flexDirection="column" alignItems="center">Résultat</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
					<Grid item xs={12}><Divider /></Grid>
					{betsFiltered.map((bet) => (
						<Grid container key={bet.id} display="flex" alignItems={"center"}>
							<Grid item xs={4}>
								<Box>#{isBook ? bet.player_bet.id : bet.id} {isBook ? bet.player_bet.name : bet.name}</Box>
								{isBook && <Box>{getNameOfPlayer(bet.player_bet.player_id)}</Box>}
								{!isBook &&
									<Box>
										{Array.isArray(bet.book_bets) && bet.book_bets.length > 0 ?
											(bet.book_bets.length > 1 ? `${bet.book_bets.length} books` : getNameOfBook(bet.book_bets[0].book_id))
											:
											"Booking"
										}
									</Box>
								}
								<Box fontSize={"14px"}>{datetimeFormat(isBook ? bet.player_bet.date : bet.date)}</Box>
							</Grid>
							<Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
								<Box fontSize={"14px"}><FormattedAmount amount={bet.amount} /> * {parseFloat(bet.cote).toString()}</Box>
								<Box><BetStatusChip status={bet.status} /></Box>
							</Grid>
							<Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
								<Box><FormattedAmount amount={bet.gain} chip gain /></Box>
								{!isBook && <Box><FormattedAmount amount={bet.commission} chip /></Box>}
							</Grid>
							<Grid item xs={1}><IconButton onClick={() => navigateToBet(bet)}><EditIcon /></IconButton> </Grid>
							<Grid item xs={12}><Divider /></Grid>
						</Grid>
					))}
				</>
			}

		</AddEditFormCard>
	)
}

export default BetsDetails