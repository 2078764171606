import { useMemo } from "react";
import { Box, Card, Grid } from "@mui/material";
import { CalculSolde } from "../../utils/MargeUtils";
import FormattedAmount from "./FormattedAmount";

const SoldeDetails = (props: { bets: any[], payments: any[], isBook: boolean }) => {
	const { bets, payments, isBook } = props;

	const playerSolde = useMemo(() => {
		return CalculSolde(bets, payments, isBook);
	}, [bets, payments]);

	return (
		<Box mt={2}>
			<Card>
				<Grid container my={1}>
					<Grid item xs={6} lg={3} display="flex" justifyContent={"flex-end"} pr={1}>En cours :</Grid>
					<Grid item xs={6} lg={3}><FormattedAmount amount={playerSolde.bet_amount_inprogress} chip gain /></Grid>

					<Grid item xs={6} lg={3} display="flex" justifyContent={"flex-end"} pr={1}>Total gain :</Grid>
					<Grid item xs={6} lg={3}><FormattedAmount amount={playerSolde.bet_amount} chip gain /></Grid>

					<Grid item xs={6}  lg={3}display="flex" justifyContent={"flex-end"} pr={1}>Total paiements :</Grid>
					<Grid item xs={6} lg={3}><FormattedAmount amount={playerSolde.payment_amount} chip gain /></Grid>

					<Grid item xs={6} lg={3} display="flex" justifyContent={"flex-end"} pr={1}>Solde :</Grid>
					<Grid item xs={6} lg={3}><FormattedAmount amount={playerSolde.solde} chip gain /></Grid>
				</Grid>
			</Card>
		</Box>
	)
}

export default SoldeDetails;