import { GridColDef } from "@mui/x-data-grid";
import { BookListModel } from "../../classes/BookModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { CalculSolde } from "../../utils/MargeUtils";
import { Box, Chip } from "@mui/material";
import { BetStatusInProgress } from "../../classes/PlayerBetsModel";
import ColumnSolde from "../common/ColumnSolde";


const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: "Book",
        editable: false,
        filterable: false,
        width: 120,
        valueGetter: ({ row }) => row.name
    }, 
    {
        field: 'agent.name',
        headerName: "Agent",
        editable: false,
        filterable: false,
        width: 120,
        valueGetter: ({ row }) => row.agent?.name
    }, 
    {
        field: 'solde',
        headerName: "Solde",
        editable: false,
        filterable: false,
        width: 180,
        renderCell: ({ row }) => <ColumnSolde bets={row.book_bets} payments={row.book_payments} isBook={true} />
    },
];


const BookList = () => {

    const routeController = "project-bets/bets/books";
    const [get] = useGetListCore<BookListModel>(`/${routeController}/index`);

    return (
        <ListCore<BookListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.books}
            getRowId={(row) => row.id}
            rowHeight={70}
            columns={columns}
            paginationOptionDefault={100}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un book"
                },
                edit: {
                    enable: true,
                    icon: true
                },
                remove: false,
                minWidth: 50
            }}
        />
    );
}

export default BookList;