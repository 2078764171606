import { Chip } from "@mui/material"
import { GetStatusColor, GetStatutLabel } from "../../classes/PlayerBetsModel"

const BetStatusChip = (props: { status: number}) => {
	const { status } = props;
	return (
		<Chip sx={{ fontSize: "11px", height: "20px"}} color={GetStatusColor(status)} label={GetStatutLabel(status)} />
	)
}

export default BetStatusChip;