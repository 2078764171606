import { useEffect, useMemo, useState } from "react";
import { useEditSettings, useGetSettings } from "../../stores/SettingsStore";
import { SettingEditModel, SettingModel } from "../../classes/settings/SettingModel";
import InputField from "../common/form/InputField";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button, Grid, Paper, TextField } from "@mui/material";
import { useField, useForm, useIsDirty } from "react-recoil-form";
import { FormattedMessage } from "react-intl";
import TextAreaField from "../common/form/TextAreaField";
import Image from "mui-image";
import FileField from "../common/form/FileField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const SettingInput = (props: { setting: SettingModel }) => {
    const { setting } = props;

    const inputType = useMemo(() => {
        var settingInput = JSON.parse(setting.input);
        return settingInput.field;
    }, [setting]);

    return (
        <>
            <input name={`${setting.id - 1}[id]`} value={setting.id} type="hidden" />
            {
                (inputType === "file") ?
                    <Box display="flex" alignItems={"center"}>
                        <FileField
                            name={`${setting.id - 1}[value_file]`}
                            label={setting.label}
                        />
                        {setting.value && <Box ml={2}><Image height={60} width={"auto"} src={setting.value} errorIcon={null} /></Box>}
                    </Box>
                    :
                    (inputType === "textarea") ?
                        <TextAreaField
                            name={`${setting.id - 1}[value]`}
                            label={setting.label}
                        /> :
                        <InputField
                            name={`${setting.id - 1}[value]`}
                            label={setting.label}
                            type="text"
                        />
            }
        </>
    );
}

const SettingsView = () => {

    const [get] = useGetSettings();
    const [edit] = useEditSettings();

    const [settings, setSettings] = useState<SettingModel[]>([]);

    const LogoField = useField<File>({ name: "1[value_file]" });

    useEffect(() => {
        get().then(res => {
            if (res.status === ReqStatus.SUCCESS && res.data && res.data.settings) {
                setSettings(res.data.settings);
                resetInitialValues(res.data.settings);
            }
        })
    }, [])

    const onSubmit = (request: SettingModel[]) => {
        const settingEditRequest: SettingEditModel[] = [];

        request.forEach((setting) => {

            let _settingEdit = {
                id: setting.id,
                value: setting.value
            } as SettingEditModel;

            if (setting.unique_key === 'company_logo' && LogoField.fieldValue) {
                _settingEdit.value_file = LogoField.fieldValue;
            }

            settingEditRequest.push(_settingEdit);
        });

        edit(settingEditRequest).then(res => {
            if (res.status === ReqStatus.SUCCESS && res.data && res.data.settings) {
                setSettings(res.data.settings);
                resetInitialValues(res.data.settings);
            }
        })
    };

    const isDirty = useIsDirty();
    const { handleSubmit, resetInitialValues } = useForm({
        onSubmit,
        //initialValues: initialValues
    })

    return (
        <AddEditForm
            formId="settingsForm"
            formHandleSubmit={handleSubmit}
            formHandleCancel={resetInitialValues}
            isDirty={isDirty}
        >
            <AddEditFormCard title="Informations entreprise" >
                <Grid container spacing={2}>
                    {settings.map((setting) => (
                        <Grid item xs={12} sm={6}>
                            <SettingInput setting={setting} />
                        </Grid>
                    ))}
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default SettingsView;