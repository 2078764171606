import { useCallback } from "react";
import { ListModelCore, ListSearchModelCore, getParamsForListRequest } from "../classes/list/ListModel";
import { GenericResponse, ReqStatus, useApi } from "./core/UseApi";
import { useGetListCore } from "./core/StoreCore";

interface OrderListModel extends ListModelCore{
    orders: any[]
}
export const useGetOrderList = () => {
    return useGetListCore<OrderListModel>('commercial/orders/index');
};

interface GetAddressesOfCustomerResponse{
    addresses: { [id: string]: string };
}
export const useGetAddressesOfCustomer = () => {
    const [callCore] = useApi<GetAddressesOfCustomerResponse, undefined>(
        {
            route:  `commercial/orders/get-customer-addresses`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : number) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};

interface GetProductInformationsResponse{
    unitTaxExcl: number,
    vat: number
}
export const useGetProductInformations = () => {
    const [callCore] = useApi<GetProductInformationsResponse, undefined>(
        {
            route:  `commercial/orders/get-product-informations`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : number) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};

interface GetShippingMethodAmountTaxExclResponse{
    amountTaxExcl: number
}
export const useGetShippingMethodAmountTaxExcl = () => {
    const [callCore] = useApi<GetShippingMethodAmountTaxExclResponse, undefined>(
        {
            route:  `commercial/orders/get-shipping-method-amount-tax-excl`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : number) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};

interface GetCountryVatResponse{
    vat: number
}
export const useGetCountryVat = () => {
    const [callCore] = useApi<GetCountryVatResponse, undefined>(
        {
            route:  `commercial/orders/get-country-vat`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : string) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};