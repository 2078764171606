import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { CalculSolde } from "../../utils/MargeUtils";
import { Alert, Box, Button, Card, Container, Divider, Grid, Paper, Tab, Tabs } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import FormattedAmount from "../common/FormattedAmount";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import ListSearchServer from "../../../components/common/list/ListSearchServer";
import { ListModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import { BetStatusInProgress } from "../../classes/PlayerBetsModel";
import { TabContext, TabList } from "@mui/lab";

interface MarginListSectionMarginProps {
	playerBets: any[],
	players: any[],
	books: any[],
	agents: any[],
	simpleDisplay: boolean
}
const MarginListSectionMargin = (props: MarginListSectionMarginProps) => {

	const { playerBets, players, books, agents, simpleDisplay } = props;

	const getNameOfAgent = useCallback((id: number) => {
		return agents.find((x) => x.id == id)?.name;
	}, [agents]);

	const getNameOfPlayer = useCallback((id: number) => {
		return players.find((x) => x.id == id)?.name;
	}, [players]);

	const getNameOfBook = useCallback((id: number) => {
		return books.find((x) => x.id == id)?.name;
	}, [players]);

	const playersOfAgents = useMemo(() => {
		const _playersOfAgents = {} as any;

		agents.forEach(agent => {
			_playersOfAgents[agent.id] = [];
		});
		// Remplir le tableau des agents avec les id des joueurs correspondants
		players.forEach(player => {
			if (_playersOfAgents[player.agent_id]) {
				_playersOfAgents[player.agent_id].push(player.id);
			}
		});
		return _playersOfAgents;
	}, [agents, players]);

	const booksOfAgents = useMemo(() => {
		const _booksOfAgents = {} as any;

		agents.forEach(agent => {
			_booksOfAgents[agent.id] = [];
		});
		// Remplir le tableau des agents avec les id des joueurs correspondants
		books.forEach(book => {
			if (_booksOfAgents[book.agent_id]) {
				_booksOfAgents[book.agent_id].push(book.id);
			}
		});
		return _booksOfAgents;
	}, [agents, books]);

	const margeDetails = useMemo(() => {

		let _marge = {
			total_com: 0,
			com: {} as any,
			com_matchs: {} as any,
			com_matchs_internal: {} as any,
			com_bookings: {} as any,

			books_total: {} as any,
			players_total: {} as any,

			diff: {} as any,
			diff_match: {} as any,
			diff_com: {} as any,
			players: {} as any,
			players_inprogress: {} as any,
			books: {} as any,
			gain: {} as any,
		}

		if (!Array.isArray(playerBets) || !Array.isArray(agents)) {
			return _marge;
		}

		agents.forEach(agent => {
			_marge.com[agent.id] = 0;

			_marge.com_bookings[agent.id] = 0;
			_marge.com_matchs[agent.id] = 0;
			_marge.com_matchs_internal[agent.id] = 0;

			_marge.books_total[agent.id] = 0;
			_marge.books[agent.id] = {} as any;


			_marge.players_total[agent.id] = 0;
			_marge.players[agent.id] = {} as any;
			_marge.players_inprogress[agent.id] = {} as any;

			_marge.diff_match[agent.id] = 0;
			_marge.diff_com[agent.id] = 0;
			_marge.diff[agent.id] = 0;
			_marge.gain[agent.id] = 0;
		});

		playerBets.forEach((b) => {
			agents.forEach((a) => {

				if (!playersOfAgents[a.id].includes(b.player_id)) {
					return;
				}

				let _com_bookings = 0;

				const _player = players.find((x) => x.id == b.player_id);
				//Joueur en interne ( pour couverture )
				if (_player.internal) {
					_marge.com_matchs_internal[a.id] += b.com;

					if (Array.isArray(b.book_bets) && b.book_bets.length > 0) {
						b.book_bets.forEach((bb: any) => {
							agents.forEach((aa) => {
								if (booksOfAgents[aa.id].includes(bb.book_id)) {
									_marge.books_total[aa.id] += bb.gain;

									if (!_marge.books[aa.id][bb.book_id]) {
										_marge.books[aa.id][bb.book_id] = bb.gain;
									} else {
										_marge.books[aa.id][bb.book_id] += bb.gain;
									}
								}
							});
						});
					}

				} else {
					if (!_marge.players[a.id][b.player_id]) {
						_marge.players[a.id][b.player_id] = b.gain;

					} else {
						_marge.players[a.id][b.player_id] += b.gain;
					}

					if (!_marge.players_inprogress[a.id][b.player_id]) {
						_marge.players_inprogress[a.id][b.player_id] = b.status === BetStatusInProgress ? b.gain : 0;
					} else {
						_marge.players_inprogress[a.id][b.player_id] += (b.status === BetStatusInProgress) ? b.gain : 0;
					}

					if (Array.isArray(b.book_bets) && b.book_bets.length > 0) {
						b.book_bets.forEach((bb: any) => {
							agents.forEach((aa) => {
								if (booksOfAgents[aa.id].includes(bb.book_id)) {
									_marge.books_total[aa.id] += bb.gain;

									if (!_marge.books[aa.id][bb.book_id]) {
										_marge.books[aa.id][bb.book_id] = bb.gain;
									} else {
										_marge.books[aa.id][bb.book_id] += bb.gain;
									}

									if (a.id != aa.id) {
										_marge.diff_match[a.id] -= bb.gain;
										_marge.diff_match[aa.id] += bb.gain;
									}
								}
							});
						});
						_marge.com_matchs[a.id] += b.com;
					} else {
						_com_bookings += b.com;
					}

					_marge.players_total[a.id] += b.gain;
				}

				_marge.com_bookings[a.id] += _com_bookings;
				_marge.com[a.id] += b.com;
				_marge.total_com += b.com;
			});
		});

		if (agents.length == 2) {
			_marge.gain[1] = Math.round((_marge.total_com / 3));
			_marge.gain[2] = Math.round(_marge.total_com - _marge.gain[1]);

			_marge.diff_com[1] = Math.round((_marge.total_com / 3) - _marge.com[1]);
			_marge.diff_com[2] = _marge.diff_com[1] ? _marge.diff_com[1] * -1 : 0;

			agents.forEach((a) => {
				_marge.diff[a.id] = _marge.diff_com[a.id] + _marge.diff_match[a.id];
			});
		}

		return _marge;
	}, [playerBets, agents, playersOfAgents, booksOfAgents]);

	const gridXs = useMemo(() => {
		return agents.length == 2 ? 6 : 12
	}, [agents])

	return (
		<>

			{simpleDisplay &&
				<Card>
					<Grid container display={"flex"} spacing={1} p={2}>
						<Grid item xs={12} display="flex">
							<Box>Commission</Box>
							<Box ml={2}><FormattedAmount amount={(margeDetails.total_com)} chip /></Box>
						</Grid>



						{agents.length == 2 && (
							<>
								<Grid item xs={12}><Divider /></Grid>
								{agents.map((a) => (

									<Grid item xs={12} key={`section_doit_${a.id}`} display="flex">
										<Box>{a.name} {margeDetails.diff[a.id] > 0 ? "RECOIT" : "DOIT"}</Box>
										<Box ml={2}><FormattedAmount amount={margeDetails.diff[a.id]} chip /></Box>

									</Grid>
								))}
							</>
						)}

						<Grid item xs={12}><Divider /></Grid>

						{agents.map((a) => (
							Object.keys(margeDetails.players[a.id]).map((p_id) => (
								<Grid item xs={12} key={`section_joueur_${p_id}`} display={"flex"}>
									<Box>{getNameOfPlayer(+p_id)}</Box>
									<Box ml={2}>
										<FormattedAmount amount={margeDetails.players[a.id][p_id]} chip gain />
									</Box>
									{margeDetails.players_inprogress[a.id][p_id] != 0 &&
										<Box ml={2} display="flex">
											En cours
											<Box ml={1}>
												<FormattedAmount amount={margeDetails.players_inprogress[a.id][p_id]} chip gain />
											</Box>
										</Box>
									}
								</Grid>
							))
						))}

					</Grid>
				</Card>
			}

			{!simpleDisplay &&
				<>
					<Card>
						<Grid container display={"flex"} spacing={1}>
							{agents.map((a) => (
								<Grid item xs={gridXs} key={`section_agent_${a.id}`}>
									<Grid container mb={1}>
										<Grid item xs={12} display={"flex"} justifyContent={"center"}>{a.name}</Grid>
									</Grid>
								</Grid>
							))}
						</Grid>
					</Card>

					<Box mt={2}>
						<Card>
							<Grid container display={"flex"} spacing={1}>
								<Grid item xs={12} display={"flex"} justifyContent={"center"} >Commissions</Grid>
								<Grid item xs={12}  ><Divider /></Grid>
								{agents.map((a) => (
									<Grid item xs={gridXs} key={`section_com_${a.id}`}>
										<Grid container mb={1}>



											<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}><b>TOTAL</b></Grid>
											<Grid item xs={6}><FormattedAmount amount={margeDetails.com[a.id]} chip /></Grid>
											<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>Match</Grid>
											<Grid item xs={6} ><FormattedAmount amount={margeDetails.com_matchs[a.id]} chip /></Grid>
											<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>Booking</Grid>
											<Grid item xs={6} ><FormattedAmount amount={margeDetails.com_bookings[a.id]} chip /></Grid>
											<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>Couverture</Grid>
											<Grid item xs={6} ><FormattedAmount amount={margeDetails.com_matchs_internal[a.id]} chip /></Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Card>
					</Box>

					<Box mt={2}>
						<Card>
							<Grid container display={"flex"} spacing={1}>

								<Grid item xs={12} display={"flex"} justifyContent={"center"} >Joueurs</Grid>
								<Grid item xs={12}  ><Divider /></Grid>
								{agents.map((a) => (
									<Grid item xs={gridXs} key={`section_players_${a.id}`}>
										<Grid container>

											<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}><b>TOTAL</b></Grid>
											<Grid item xs={6} ><FormattedAmount amount={margeDetails.players_total[a.id]} chip gain /></Grid>

											{Object.keys(margeDetails.players[a.id]).map((p_id) => (
												<>
													<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>
														{getNameOfPlayer(+p_id)}
													</Grid>
													<Grid item xs={6} ><FormattedAmount amount={margeDetails.players[a.id][p_id]} chip gain /></Grid>
												</>
											))}

										</Grid>

									</Grid>
								))}


								<Grid item xs={12} display={"flex"} justifyContent={"center"}>En cours</Grid>

								{agents.map((a) => (
									<Grid item xs={gridXs} key={`section_players_inprogress_${a.id}`}>
										<Grid container>

											{Object.keys(margeDetails.players_inprogress[a.id]).map((p_id) => (
												<>
													{margeDetails.players_inprogress[a.id][p_id] != 0 &&
														<>
															<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>
																{getNameOfPlayer(+p_id)}
															</Grid>
															<Grid item xs={6} ><FormattedAmount amount={margeDetails.players_inprogress[a.id][p_id]} chip gain /></Grid>
														</>
													}
												</>
											))}

										</Grid>

									</Grid>
								))}
							</Grid>
						</Card>
					</Box>


					<Box mt={2}>
						<Card>
							<Grid container display={"flex"} spacing={1}>
								<Grid item xs={12} display={"flex"} justifyContent={"center"} >Books</Grid>
								<Grid item xs={12}  ><Divider /></Grid>
								{agents.map((a) => (
									<Grid item xs={gridXs} key={`section_books_${a.id}`}>
										<Grid container mb={1}>

											<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}><b>TOTAL</b></Grid>
											<Grid item xs={6} ><FormattedAmount amount={margeDetails.books_total[a.id]} chip gain /></Grid>

											{Object.keys(margeDetails.books[a.id]).map((b_id) => (
												<>
													<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>
														{getNameOfBook(+b_id)}
													</Grid>
													<Grid item xs={6} ><FormattedAmount amount={margeDetails.books[a.id][b_id]} chip gain /></Grid>
												</>
											))}


										</Grid>
									</Grid>
								))}
							</Grid>
						</Card>
					</Box>

					{agents.length == 2 &&
						<Box mt={2}>
							<Card>
								<Grid container display={"flex"} spacing={1}>

									<Grid item xs={12} display={"flex"} justifyContent={"center"} >Récapitulatif</Grid>


									<Grid item xs={12}  ><Divider /></Grid>


									{agents.map((a) => (
										<Grid item xs={6} key={`section_recap_${a.id}`}>
											<Grid container mb={1}>
												<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>Matchs</Grid>
												<Grid item xs={6} ><FormattedAmount amount={(margeDetails.diff_match[a.id])} chip gain /></Grid>
											</Grid>

											<Grid container mb={1}>
												<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>Com</Grid>
												<Grid item xs={6} ><FormattedAmount amount={(margeDetails.diff_com[a.id])} chip gain /></Grid>
											</Grid>

											<Grid container mb={1}>
												<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>{a.name} {margeDetails.diff[a.id] > 0 ? "RECOIT" : "DOIT"}</Grid>
												<Grid item xs={6} ><FormattedAmount amount={margeDetails.diff[a.id]} chip /></Grid>
											</Grid>


										</Grid>
									))}

									<Grid item xs={12}  ><Divider /></Grid>
									{agents.map((a) => (
										<Grid item xs={6} key={`section_gain_${a.id}`}>
											<Grid container mb={1}>
												<Grid item xs={6} display="flex" justifyContent={"flex-end"} pr={1}>Gain</Grid>
												<Grid item xs={6} ><FormattedAmount amount={(margeDetails.gain[a.id])} chip /></Grid>
											</Grid>
										</Grid>
									))}

								</Grid>
							</Card>
						</Box>
					}

				</>
			}
		</>
	)
}

export default MarginListSectionMargin;