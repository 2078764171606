import { useCallback, useEffect, useState } from "react";
import { useGetUserGroupsList } from "../../stores/UserGroupsStore";
import { UserGroupModel } from "../../classes/user-groups/UserGroupModel";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button, Grid, IconButton, Paper, Skeleton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import UserGroupView from "./UserGroupView";
import { useDeleteUserGroup } from '../../stores/UserGroupsStore';
import GroupAuthorizationView from "../group-authorizations/GroupAuthorizationView";
import { useNavigate } from "react-router";
import { ConfirmModalState } from "../../states/ModalState";
import { useSetRecoilState } from "recoil";

const UserGroupList = () => {

    const navigate = useNavigate();
    const [userGroupList, setUserGroupList] = useState<UserGroupModel[]>([]);
    const [getList] = useGetUserGroupsList();
    const [deleteAjax] = useDeleteUserGroup();
    const setConfirmModalState = useSetRecoilState(ConfirmModalState);

    const refreshList = () => {
        setReady(false)
        getList().then(res => {
            if (res.status === ReqStatus.SUCCESS && res.data && Array.isArray(res.data.userGroups)) {
                setUserGroupList(res.data.userGroups);
            }
        }).then(() => setReady(true))
    }

    const [ ready, setReady ] = useState(false);

    const addUserGroup = () => {
        navigate(`/user-groups/add-edit`);
    }

    const editUserGroup = (id: number) => {
        navigate(`/user-groups/add-edit/${id}`);
    }

    const editGroupAuthorization = (id: number) => {
        navigate(`/group-authorizations/view/${id}`);
    }

    const deleteGroupUser = useCallback((id: number) => {

        if (!id) { return; }

        const userGroupToDelete = userGroupList.find(x => x.id === id);
        if (!userGroupToDelete) { return; }

        setConfirmModalState({
            open: true,
            title: "Suppression",
            desc: `Êtes-vous sûr de vouloir supprimer le rôle ${userGroupToDelete.name} ?`,
            validEvent: () => {
                deleteAjax(id).then((res) => {
                    if (res.status === ReqStatus.SUCCESS && res.data) {
                        refreshList();
                    }
                })
            }
        });
    },[deleteAjax, refreshList, setConfirmModalState, userGroupList]);

    useEffect(() => {
        refreshList();
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: "Rôle",
            editable: false,
            filterable: false,
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'actions',
            headerName: "",
            editable: false,
            sortable: false,
            filterable: false,
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }) => {
                return (<>
                    <Box display="flex" justifyContent={"flex-end"} sx={{ width: "100%" }}>
                        <IconButton color="primary" onClick={(e) => { editGroupAuthorization(row.id); }}><KeyIcon /></IconButton>
                        <IconButton color="warning" onClick={(e) => { editUserGroup(row.id); }}><EditIcon /></IconButton>
                        <IconButton color="error" onClick={(e) => { deleteGroupUser(row.id); }}><DeleteIcon /></IconButton>
                    </Box>
                </>
                );
            }
        }
    ];

    return (
        <Grid container display={"flex"} justifyContent={"center"} >
            <Grid item xs={12} sm={8} md={6} >
                <Box m={2}>
                    <Box display="flex" justifyContent={"flex-end"} mb={2}>
                        <Button color="primary" variant="contained" onClick={addUserGroup}>Ajouter un rôle</Button>
                    </Box>
                    <Paper variant="outlined" sx={{ width: "100%" }}>
                        <Box>
                            { !ready && <Skeleton variant="rectangular" height={200}/> }
                            { ready && 
                            <DataGrid
                                rows={userGroupList}
                                columns={columns}
                                paginationModel={{
                                    page: 0,
                                    pageSize: 10
                                }
                                }
                                getRowId={(row) => { return row.id; }}
                                disableRowSelectionOnClick
                            />
                            }
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
}

export default UserGroupList;