export interface SearchItemModelCore{
	datasource : {[nomPropriete: number]: string}
	entity: string,
	html: {
		attrs?: { placeholder:string }
		field: "text" | "select" | "date" | 'datetime' | 'checkbox' | 'autocomplete',
		empty? : string,
		label? : string
	},
	value: any
}

export interface ListExportButtonsModelCore {
	csv? : boolean,
	pdf? : boolean,
    xls? : boolean,
}

export interface ListSearchModelCore {[nomPropriete: string]: SearchItemModelCore}

export interface ListModelCore {
	search : {[nomPropriete: string]: SearchItemModelCore},
	exportButtons : ListExportButtonsModelCore;
	count: number
};


export function getParamsForListRequest(page: number, search? : ListSearchModelCore, csv? : boolean, pdf?: boolean, xls?: boolean, reinit? : boolean){
	let urlParamsArr: string[] = [];
	if(!search){ return ''; }

	if(page > 0){
		urlParamsArr.push(`page=${page+1}`)
	}

	if(csv){
		urlParamsArr.push(`csv=1`)
	}

	if(pdf){
		urlParamsArr.push(`pdf=1`)
	}

    if(xls){
        urlParamsArr.push(`xls=1`)
    }

	Object.entries(search).forEach(([searchKey, searchItem]) => {

		if( searchItem.html.field === 'checkbox' ){
			urlParamsArr.push(`search[${searchKey}]=${searchItem.value ? 1 : 0}`);
		}
		else if( searchItem.html.field === 'select' ){

			if(searchItem.value !== -1 && searchItem.value !== null && searchItem.value !== undefined){
				urlParamsArr.push(`search[${searchKey}]=${searchItem.value}`);
			}else if( !reinit  ){
				urlParamsArr.push(`search[${searchKey}]=`);
			}
		}
		else if( searchItem.value){
			urlParamsArr.push(`search[${searchKey}]=${searchItem.value}`);
		}
	});

	return urlParamsArr.length > 0 ? `?${urlParamsArr.join("&")}` : '';
}
