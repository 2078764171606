import {
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  makeStyles,
  SxProps,
  Theme
} from "@mui/material";
import { IAncestorInput, useField } from "react-recoil-form";
import { isValidEmail, isValidPhone } from "../../../utils/FormUtils";
import { useCallback } from "react";

interface IFieldProps {
  inputRef?: React.Ref<any>,
  name: string;
  ancestors?: IAncestorInput[];
  type: string;
  label?: string;
  required?: boolean;
  isEmail?: boolean;
  isPhone?: boolean;
  disabled?: boolean;
  endAdornment? :JSX.Element;
  endAdornmentSx? : SxProps<Theme>
  textAlign? : "left" | "center" | "right",
  onBlurActive? : boolean,
  size? : 'small' | 'medium'
}

export default function InputField(props: IFieldProps) {
  const { name, ancestors, type, label, required, isEmail, isPhone, disabled, endAdornment, endAdornmentSx, textAlign, onBlurActive, size, inputRef } = props;
  const { fieldValue, setFieldValue, error } = useField({
    ancestors,
    name,
    validate: (value) => {
      if (required && !value) {
        return `Le champ ${label} est requis`;
      }
      if (value && isEmail) {
        if (!isValidEmail(value)) {
          return "L'email est invalide";
        }
      }
      if (value && isPhone) {
        /*if (!isValidPhone(value)) {
          return "Le téléphone est invalide";
        }*/
      }
    }
  });

  const setDefaultValue = useCallback(() => {
    return (type === "number") ? undefined : "";
  },[type]);



  return (
    <FormControl error={!!error} fullWidth sx={type === "hidden" ? { display: "none" } : undefined}>
      <TextField
        inputRef={inputRef}
        id={name}
        type={type}

        disabled={disabled ?? false}
        label={label}
        required={required}
        variant={"outlined"}
        size={size}
        InputProps={{
          inputProps: {
            style: { 
              textAlign: textAlign ?? "left"
            },
          },
          endAdornment: (endAdornment ? 
            <InputAdornment position="end" sx={endAdornmentSx} >
              {endAdornment}
            </InputAdornment>
            : <></>
          ),
        }}

        {...(!onBlurActive && {
          value: fieldValue || "",
          onChange: (e) => setFieldValue(e.target.value ? e.target.value : setDefaultValue()),
        })}

        {...(onBlurActive && {
          defaultValue: fieldValue || "",
          onBlur: (e) => setFieldValue(e.target.value ? e.target.value : setDefaultValue()),
        })}

      />
      {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
    </FormControl>
  );
}
