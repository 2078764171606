import { useCallback } from "react";
import { ListModelCore, ListSearchModelCore, getParamsForListRequest } from "../../classes/list/ListModel";
import { GenericResponse, ReqStatus, useApi } from "./UseApi";

export const useGetListCore = function <T extends ListModelCore>(route: string, paramsCompl? : string): [(paginationLimit: number, page: number, search?: ListSearchModelCore, csv? : boolean, pdf? : boolean) => Promise<GenericResponse<T>>, ReqStatus] {
    const [callCore, status] = useApi<T, any>(
        {
            route: route,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback(( paginationLimit: number, page: number, search?: ListSearchModelCore, csv? : boolean, pdf? : boolean, reinit? : boolean) => {
        let params = getParamsForListRequest(page, search, csv, pdf, reinit);

        if(paramsCompl){
            if(params === ''){
                params = `?${paramsCompl}`;
            }else{
                params = `${params}&${paramsCompl}`;
            }
        }

        if(csv || pdf){
            //Ouverture directement vers l'url du back-end pour le téléchargement du fichier.
            window.open(`${process.env.REACT_APP_API}${route}${params}`, '_blank');
            return new Promise<any>((resolve, reject) => {});
        }

        return callCore({
            paginationLimit: paginationLimit
        }, `${params}`);
    }, [callCore]);
    return [call, status];
};

export const useAddEditCore = <TRequest, TResponse>(route: string) => {
    const [get, getStatus] = useApi<TResponse, undefined>(
        {
            route: route,
            instanceAxiosMethod: "GET"
        }
    );

    const [addEdit, addEditStatus] = useApi<TResponse, TRequest>(
        {
            route: route,
            instanceAxiosMethod: "POST"
        }
    );

    const getData = useCallback((id? : string) => {
        return get( undefined, `/${id ?? ""}` );
    }, [get]);

    const addEditData = useCallback((data: TRequest, id : string | undefined) => {
        return addEdit(data, id ? `/${id}` : '');
    }, [addEdit]);

    return { getData, addEditData , getStatus, addEditStatus}
};

export const useViewCore = <TResponse>(route: string) => {
    const [get, getStatus] = useApi<TResponse, undefined>(
        {
            route: route,
            instanceAxiosMethod: "GET"
        }
    );

    const getView = useCallback((id : string) => {
        return get( undefined, `/${id}` );
    }, [get]);

    return getView
};

export const useDelete = function (routeController: string){
    const [callCore] = useApi<{}, undefined>(
        {
            route: `${routeController}/delete`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id: number  | string) => {
        return callCore( undefined, `/${id}` );
    }, [callCore]);
    return call;
};